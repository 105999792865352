<template>
  <div>
    <!-- 导入excel弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('gantt.importGantt')"
      width="25%"
      center
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form ref="newFileForm">
        <el-form-item>
          <el-input
            v-model="formName"
            autocomplete="off"
            :placeholder="$t('homeTopBar.newFileForm.name')"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="fileTypeId"
            :placeholder="$t('homeTopBar.newFileForm.type')"
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(option, index) in form_options"
              :key="index"
              :label="option.name"
              :value="option.fileTypeId"
              >{{ option.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 上传excel弹窗 -->
    <el-dialog
      :visible.sync="uplodaVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      center
      class="upexcel"
    >
      <div v-loading="isLoading">
        <div
          slot="title"
          style="
            color: #30648f;
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 10px;
            padding-top: 10px;
          "
        >
          {{ $t("homeTopBar.newFileForm.excel.title") }}
        </div>
        <p style="font-weight: bold; color: #30648f">
          {{ $t("homeTopBar.newFileForm.excel.p5") }}
        </p>
        <p>
          {{ $t("homeTopBar.newFileForm.excel.p1") }}
        </p>
        <p>
          {{ $t("homeTopBar.newFileForm.excel.p2") }}
        </p>
        <p>{{ $t("homeTopBar.newFileForm.excel.p6") }}</p>
        <p>
          {{ $t("homeTopBar.newFileForm.excel.p3") }}
          <a :href="$t('ganttExcelTemplate')">
            {{ $t("homeTopBar.newFileForm.excel.p4") }}
          </a>
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          style="margin-right: 10px"
          @click="createExcel_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>

        <el-upload
          class="upload-demo"
          action="#"
          accept=".xlsx, .xls, .doc, .docx"
          :http-request="upload_attachment_handler"
          :show-file-list="false"
          :before-upload="judge_howBig"
        >
          <el-button size="small" type="primary"
            >{{ $t("btn.importBtn") }}
          </el-button>
        </el-upload>
      </div>
    </el-dialog>
    <el-dialog
      v-loading="isLoading1"
      :visible.sync="excelVisible"
      width="25%"
      center
      class="upexcel"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <span>{{ $t("homeTopBar.newFileForm.excel.excelField") }}</span>
        <i
          class="iconfont icon-a-left_arrow11"
          style="justify-self: center; margin: 0 10px"
        />
        <span>{{ $t("homeTopBar.newFileForm.excel.sysField") }}</span>
      </div>
      <div>
        <div
          class=""
          style="max-height: 60vh; overflow: hidden scroll; margin-bottom: 20px"
        >
          <div v-for="(i, index) in headList" :key="i" class="grid-excel">
            <div class="" style="line-height: 16px">{{ i }}</div>
            <div class="">
              <el-select
                v-model="f_data[index]"
                :placeholder="$t('placeholder.dropdown')"
                style="width: 100%"
                filterable
              >
                <el-option
                  v-for="item in fields"
                  :key="item.fieldId"
                  :label="item.name"
                  :value="[item.name, item]"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          style="margin-right: 5px"
          @click="createExcelKey_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          :loading="lastStepLoading"
          class="button-confirm"
          @click="createExcelKey_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { importExcel, importLastStep } from "@/network/gantt/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import { mapGetters } from "vuex";
import { Descripitions } from "element-ui";
export default {
  props: {
    ganttChartId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formName: "",
      fileTypeId: "",
      dialogVisible: false,
      uplodaVisible: false,
      excelVisible: false,
      isLoading: false,
      isLoading1: false,
      lastStepLoading: false,
      f_data: [],
      headList: [],
      const_fields: [],
      excelRedisUUID: "",
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
    form_options() {
      return Object.values(this.fileType);
    },
  },
  watch: {
    f_data() {
      //监听用户已选择的数据，并把已选择的从列表里删去
      let list = [];
      for (let i of this.f_data) {
        if (i.length > 0) {
          list.push(i[1]);
        }
      }
      let list2 = [];
      for (let i of this.const_fields) {
        let f = true;
        for (let j of this.f_data) {
          if (i["name"] == j[0]) {
            f = false;
          }
        }
        if (f) {
          list2.push(i);
        }
      }
      this.fields = list2;
    },
  },
  methods: {
    comfirm() {
      //新建批量上传文件
      if (!this.formName) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.name"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      if (!this.fileTypeId) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.type"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      get_filetype_detail(this.get_pid(), this.fileTypeId).then((res) => {
        let li = {};
        this.fields = [];
        this.fields.push({
          name: this.$t("homeTopBar.newFileForm.field1"),
          fieldId: "assignee",
        });
        this.fields.push({
          name: this.$t("homeTopBar.newFileForm.field2"),
          fieldId: "description",
        });
        res.fileTypeLayerList.forEach((i) => {
          i.fieldList.forEach((item) => {
            li[item.fieldId] ? "" : (li[item.fieldId] = item);
          });
        });
        this.fields.push(...Object.values(li));
      });
      this.uplodaVisible = true;
    },
    cancel() {
      //新建excel 取消
      this.dialogVisible = false;
      this.formName = "";
      this.fileTypeId = "";
    },
    showDialog() {
      this.dialogVisible = true;
    },
    createExcel_cancel() {
      this.uplodaVisible = false;
      this.formName = "";
      this.fileTypeId = "";
    },
    createExcelKey_cancel() {
      //上传excel键值对 取消
      this.uplodaVisible = false;
      this.excelVisible = false;
      this.formName = "";
      this.fileTypeId = "";
      this.excelRedisUUID = "";
      this.headList = [];
      this.form_options = [];
    },
    createExcelKey_comfirm() {
      let flag = true;
      //创建excel键值对
      for (let i in this.f_data) {
        if (this.f_data[i].length < 1) {
          flag = false;
        }
      }
      //构建上传接口data
      if (!flag) {
        this.$confirm(
          this.$t("file.dialog.tip1"),
          this.$t("file.dialog.tip2"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            type: "warning",
          }
        )
          .then(() => {
            this.creatExcelFn();
          })
          .catch(() => {});
      } else {
        this.creatExcelFn();
      }
    },
    judge_howBig(file) {
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          type: "error",
          message: this.$t("homeTopBar.newFileForm.limit"),
          duration: "5000",
        });
        return false;
      }
    },
    async upload_attachment_handler(params) {
      //上传excel
      const self = this;
      self.isLoading = true;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client
        .put(`import_excel_word/${ulid()}${file.name}`, file)
        .then((ossres) => {
          const arr = params.file.name.split(".");
          if (arr[arr.length - 1] === "xls" || arr[arr.length - 1] === "xlsx") {
            importExcel({
              projectId: this.get_pid(),
              params: {
                ossFileKey: ossres.name,
              },
            })
              .then((res) => {
                self.excelRedisUUID = res.excelRedisUUID;
                self.headList = res.headList;
                if (res.headList.length < 1) {
                  self.creatExcelFn();
                } else {
                  self.uplodaVisible = false;
                  self.isLoading = false;
                  self.excelVisible = true;
                  // 构建浮窗字段
                  self.const_fields = JSON.parse(JSON.stringify(self.fields));
                  self.f_data = [];
                  for (let i in self.headList) {
                    self.f_data.push("");
                  }
                }
              })
              .catch((err) => {
                this.isLoading = false;
              });
          }
        })
        .error((r) => {
          self.isLoading = false;
          self.$message.error(
            self.$t("homeTopBar.newProjectForm.message.uploadError")
          );
        });
    },
    creatExcelFn() {
      this.lastStepLoading = true;
      const mappingFieldList = [];
      this.f_data.forEach((item, index) => {
        if (item) {
          let name = "";
          if (item[1].name === "Assignee") {
            name = "assignee";
          } else if (item[1].name === "Description") {
            name = "description";
          } else {
            name = item[1].name;
          }
          const obj = {
            excelName: this.headList[index],
            filedName: name,
            fieldId: item[1].fieldId,
          };
          mappingFieldList.push(obj);
        }
      });
      const params = {
        projectId: this.get_pid(),
        ganttChartId: this.ganttChartId,
        data: {
          fileName: this.formName,
          fileTypeId: this.fileTypeId,
          excelRedisUUID: this.excelRedisUUID,
          mappingFieldList: mappingFieldList,
        },
      };
      importLastStep(params)
        .then((res) => {
          this.dialogVisible = false;
          this.uplodaVisible = false;
          this.excelVisible = false;
          this.lastStepLoading = false;
          this.isLoading = false;
          this.isLoading1 = false;
          this.formName = "";
          this.fileTypeId = "";
          this.excelRedisUUID = "";
          this.headList = [];
          this.form_options = [];
          this.$emit("reLoadNodes");
        })
        .catch((res) => {
          this.lastStepLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  display: flex;
  justify-content: right;
}
.upexcel {
  a {
    user-select: none;
    -webkit-appearance: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #60a3d9 !important;
    font-style: normal;
  }

  p {
    font-size: 1em;
    line-height: 140%;
  }
}
.grid-excel {
  display: grid;
  grid-template-columns: 30% 70%;
  // grid-column-gap: 10%;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
