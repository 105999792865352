<template>
  <div
    ref="gantt_wrap"
    v-loading="loading"
    class="gantt_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div v-if="hasGanttChart" class="gantt_main_wrap">
      <el-collapse-transition>
        <div
          v-show="show_top"
          style="display: flex; flex-direction: column"
          class="show_top"
        >
          <div class="top_options">
            <el-tooltip
              effect="dark"
              placement="top"
              :content="selectedGanttChart.name"
              :disabled="!isShowTooltip"
            >
              <el-select
                v-model="cur_gant_id"
                :disabled="is_show_chart"
                size="small"
                filterable
                popper-class="select_own"
                @change="ganttChange"
              >
                <el-option
                  :key="'add_new'"
                  class="test-add-option"
                  :value="cur_gant_id"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: center;
                      "
                      class="test-add"
                      @click="gant_add"
                    >
                      <i class="iconfont icon-add"></i>
                    </div>
                  </div>
                </el-option>
                <el-option
                  v-for="item in ganttChartsList"
                  :key="item.ganttChartId"
                  :label="item.name"
                  :value="item.ganttChartId"
                >
                </el-option>
              </el-select>
            </el-tooltip>
            <i
              style="
                position: relative;
                left: 10px;
                top: 5px;
                cursor: pointer;
                font-size: 23px;
                color: rgb(48, 100, 143);
              "
              class="iconfont"
              :class="is_show_chart ? 'icon-chart_on' : 'icon-chart'"
              @click="show_chart"
            />
            <div v-show="!is_show_chart" class="right_button_wrap">
              <!-- <el-button
                class="el-btn"
                style="
                  background-color: #154da8;
                  width: 100%;
                  color: #fff;
                  margin-right: 20px;
                "
                @click="important_route"
                >关键路径
              </el-button> -->
              <div v-if="is_show_important_route" class="important_route_btn_0">
                <el-button
                  round
                  size="mini"
                  class="btn"
                  @click="important_route"
                  >{{ $t("btn.important_route")
                  }}<i class="iconfont icon-pwo" style="margin-left: 5px"></i
                ></el-button>
              </div>
              <div v-else class="important_route_btn_1">
                <el-button
                  round
                  size="mini"
                  class="btn"
                  @click="important_route"
                  >{{ $t("btn.important_route")
                  }}<i class="iconfont icon-pwc" style="margin-left: 5px"></i
                ></el-button>
              </div>
              <!-- 自动排期 -->
              <el-dropdown style="border: none" @command="autoSchedule">
                <el-button
                  class="el-btn"
                  style="background-color: #154da8; width: 100%; color: #fff"
                >
                  {{ $t("gantt.AutomaticScheduling")
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in autoScheduleList"
                    :key="item.key"
                    :command="item"
                  >
                    <div>{{ item.title }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 时间显示 -->
              <el-dropdown style="border: none" @command="handleCommandCell">
                <el-button
                  class="el-btn"
                  style="background-color: #154da8; width: 100%; color: #fff"
                >
                  {{ top_options.show_zoom
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in cellList.filter((a) => {
                      return a.title !== top_options.show_zoom;
                    })"
                    :key="item.key"
                    :command="item"
                  >
                    <div>{{ item.title }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 责任人显示 -->
              <el-dropdown style="border: none" @command="handleCommandLegend">
                <el-button
                  class="el-btn"
                  style="background-color: #154da8; width: 100%; color: #fff"
                >
                  {{ top_options.show_legend
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in legendList.filter((a) => {
                      return a.legendName !== top_options.show_legend;
                    })"
                    :key="item.legendId"
                    :command="item"
                  >
                    <div>{{ item.legendName }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                :hide-on-click="false"
                trigger="click"
                style="border: none; padding: 0"
                @command="importFn"
              >
                <i
                  style="cursor: pointer; font-size: 22px"
                  class="iconfont icon-save-list"
                ></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="import">
                    <div style="width: 100%">{{ $t("btn.importBtn") }}</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-dropdown
                      trigger="click"
                      style="width: 100%"
                      @command="exportFn"
                    >
                      <div style="width: 100%">{{ $t("btn.exportBtn") }}</div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="excel"
                          >excel</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <i
                class="iconfont icon-a-31shezhi"
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  font-size: 28px;
                  color: rgb(48, 100, 143);
                "
                @click.stop="showDependRuleDialog"
              ></i>
              <i
                style="
                  cursor: pointer;
                  font-size: 22px;
                  color: rgb(48, 100, 143);
                "
                class="iconfont icon-delete"
                @click.stop="() => (dialogDeleteGanttVisible = true)"
              ></i>
            </div>
          </div>
          <div
            class="sprint_target"
            style="flex: 1; display: flex; flex-direction: column"
            v-if="!is_show_chart"
          >
            <div style="padding-bottom: 10px; font-weight: bold">
              {{ $t("gantt.Desc") }}:
            </div>
            <el-scrollbar style="max-height: 80px; overflow-x: hidden; flex: 1">
              <!-- <span v-html="newDescription"></span> -->
              <div
                style="
                  font-size: 16px;
                  margin-left: 6px;
                  height: 100%;
                  word-break: break-all;
                "
              >
                <!-- {{ showDesc(selectedGanttChart.description) }} -->
                <span v-html="newDescription"></span>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </el-collapse-transition>

      <div
        class="sprint_options_hide"
        :class="is_show_chart ? 'showchartgantt' : ''"
        v-if="!is_show_chart"
        @click="show_top = !show_top"
      >
        <span v-if="show_top"
          ><i class="iconfont icon-fold_up" style="color: #000" />
          {{ $t("switch.PutAway") }}</span
        >
        <span v-else
          ><i class="iconfont icon-fold_down" style="color: #000" />
          {{ $t("switch.Expand") }}</span
        >
      </div>
      <div
        v-show="is_show_chart"
        @mouseenter="summaryOnmouserEnter"
        @mouseleave="summaryOnmouserLeave"
      >
        <el-collapse v-model="activeNames">
          <el-collapse-item name="2">
            <div slot="title" class="table1-title collapse-title">
              <span style="margin-right: 20px; font-weight: 700">{{
                $t("gantt.table.GanttChartDataSummary")
              }}</span>
            </div>
            <el-table
              :data="allGanttCurrentData"
              style="width: 100%"
              :header-cell-style="{
                color: '#383838',
                'font-weight': '700',
              }"
              height="280"
              ref="allGanttTable"
              @selection-change="handleSelectionChange"
              @select-all="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                prop="gantt"
                :label="$t('gantt.info2')"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{
                    ganttChartsList.filter((item) => {
                      return item.ganttChartId === scope.row.ganttChartUuid;
                    })[0].name
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="" :label="$t('quick.nodeStatus')">
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                    :disabled="!scope.row.ganttStatusDistributionList"
                    popper-class="table1-bar-popper"
                  >
                    <div slot="content">
                      <div
                        v-for="(item, index) in scope.row
                          .ganttStatusDistributionList"
                        :key="index"
                        class="table1-bar-line"
                      >
                        <span
                          class="table1-bar-tip-icon"
                          :class="{
                            status_todo:
                              status[item.status].statusCategory == 'TODO',
                            status_progress:
                              status[item.status].statusCategory ==
                              'IN_PROGRESS',
                            status_open:
                              status[item.status].statusCategory == 'DONE',
                          }"
                        ></span
                        >{{ status[item.status].name }}: {{ item.count }}
                      </div>
                      <div>
                        {{ $t("quick.Total") }}:
                        {{ getCount(scope.row.ganttStatusDistributionList) }}
                      </div>
                    </div>
                    <div
                      class="table1-bar"
                      :style="{
                        width: getWrapWidth(
                          scope.row.ganttStatusDistributionList
                        ),
                      }"
                    >
                      <span
                        v-for="(item, index) in scope.row
                          .ganttStatusDistributionList"
                        :key="item.status"
                        :style="{
                          width: getWidth(
                            item,
                            scope.row.ganttStatusDistributionList
                          ),
                        }"
                        :class="{
                          radius_border_left: index === 0,
                          radius_border_right:
                            index ===
                            scope.row.ganttStatusDistributionList.length - 1,
                          radius_border_both:
                            index === 0 &&
                            index ===
                              scope.row.ganttStatusDistributionList.length - 1,
                          status_todo:
                            status[item.status].statusCategory == 'TODO',
                          status_progress:
                            status[item.status].statusCategory == 'IN_PROGRESS',
                          status_open:
                            status[item.status].statusCategory == 'DONE',
                        }"
                      ></span>
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="table1-footer">
              <el-pagination
                :current-page="table1pages.current"
                background
                :page-size="6"
                layout="total, prev, pager, next, jumper"
                :total="table1pages.total"
                @current-change="table1pagesHandleCurrentChange"
              >
              </el-pagination>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div
        v-show="is_show_chart"
        id="echarts_main_wrap"
        ref="echarts_main_wrap"
        class="echarts_main_wrap"
      >
        <div class="bar1">
          <div id="bar1" ref="bar1"></div>
        </div>
        <div class="bar1">
          <div id="bar2" ref="bar2"></div>
        </div>
        <div class="bar1">
          <div v-if="tableshow" class="tiptitle3" style="height: 40px">
            {{ $t("gantt.chart.title.title4") }}
          </div>
          <div
            v-if="tableshow"
            id="bar3"
            ref="bar3"
            style="height: calc(100% - 50px)"
          >
            <el-table
              style="width: 100%"
              height="100%"
              :data="tablechartData"
              size="mini"
              header-row-class-name="tablechartDataheader"
              :header-cell-style="{
                'background-color': '#e8eff7',
                color: 'black',
              }"
              :cell-style="columnStyle"
              @cell-click="tablechartData_click"
            >
              <el-table-column prop="respeople" width="80" align="center">
                <template slot="header" slot-scope="scope">
                  <div class="respeople_container">
                    <div class="line"></div>
                    <div class="left">{{ $t("gantt.chart.title.title2") }}</div>
                    <div class="right">
                      {{ $t("gantt.chart.title.title1") }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                v-for="(item, index) in tableStatusAll"
                :key="index"
                :prop="item"
                :label="item"
                align="center"
                :width="setWidth(item)"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="bar1">
          <div id="bar4" ref="bar4"></div>
        </div>
      </div>

      <div v-show="!is_show_chart" class="header_group">
        <div class="left_group">
          <div style="position: absolute; z-index: 999">
            <div class="wrap_img" style="cursor: pointer; background: white">
              <img
                class="group_img"
                src="@/assets/img/grouping.png"
                alt=""
                @click.stop="blurDiv"
              />
            </div>
            <div
              v-if="isShowTableList"
              class="wrap_select"
              style=""
              @click.stop
            >
              <el-checkbox v-model="checkList.key" label="Key"></el-checkbox>
              <el-checkbox
                v-model="checkList.person"
                :label="$t('nodeDetail.basicInfo.responsibility')"
              ></el-checkbox>
              <el-checkbox
                v-model="checkList.all_time"
                :label="$t('gantt.info3')"
              ></el-checkbox>
              <el-checkbox
                v-model="checkList.status"
                :label="$t('gantt.status')"
              ></el-checkbox>
              <el-checkbox
                v-model="checkList.work_hours"
                :label="$t('gantt.workHours')"
              ></el-checkbox>
            </div>
          </div>

          <div id="header_tree_node" class="header">
            <div class="header_node_name">
              <div class="node_name">
                <div class="node">
                  {{ $t("gantt.nodeName") }}
                </div>
              </div>
            </div>

            <span
              id="add_root"
              v-limits-of-authority="'NODE_EDIT'"
              style="
                border: none;
                background: transparent;
                margin-left: 5px;
                cursor: pointer;
              "
              @click="beforeInsertRootNode"
            >
              <!--                  <svg-icon icon-class="add_blue"></svg-icon>-->
              <i class="iconfont icon-add" style="color: rgb(76, 124, 159)"></i>
            </span>

            <div v-if="checkList.key" class="header_node_key">Key</div>
            <div v-if="checkList.person" class="header_node_person">
              <el-dropdown
                :class="{ isSelected: personFilter.length }"
                style="cursor: pointer"
              >
                <span
                  class="el-dropdown-link el-dropdown-link-select center_span"
                >
                  {{ $t("nodeDetail.basicInfo.responsibility") }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div>
                    <el-checkbox
                      v-model="checkAllPerson"
                      :indeterminate="isIndeterminatePerson"
                      class="checkbox"
                      style="margin-top: 10px"
                      @change="handleCheckAllChange($event, 'person')"
                    >
                      &nbsp;&nbsp;&nbsp;{{ $t("gantt.selectAll") }}</el-checkbox
                    >
                    <el-checkbox-group
                      v-model="personFilter"
                      class="select"
                      @change="handleCommandFilter('check')"
                    >
                      <el-checkbox
                        v-for="(item, index) in personSet2()"
                        :key="index"
                        :label="item.value"
                        class="checkbox"
                      >
                        &nbsp;&nbsp;&nbsp;{{ item.label }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-if="checkList.status" class="header_node_status">
              <span>
                <el-dropdown
                  :class="{ isSelected: statusFilter.length }"
                  style="cursor: pointer"
                >
                  <span
                    class="el-dropdown-link el-dropdown-link-select center_span"
                  >
                    {{ $t("gantt.status") }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div>
                      <el-checkbox
                        v-model="checkAllStatus"
                        :indeterminate="isIndeterminateStatus"
                        class="checkbox"
                        style="margin-top: 10px"
                        @change="handleCheckAllChange($event, 'status')"
                      >
                        &nbsp;&nbsp;&nbsp;{{
                          $t("gantt.selectAll")
                        }}</el-checkbox
                      >
                      <el-checkbox-group
                        v-model="statusFilter"
                        class="select"
                        @change="handleCommandFilter('check')"
                      >
                        <el-checkbox
                          v-for="(item, index) in statusSet2()"
                          :key="index"
                          :label="item"
                          class="checkbox"
                        >
                          &nbsp;&nbsp;&nbsp;{{ item }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <div
              :class="
                currentLang == 'zh'
                  ? 'header_node_start_time'
                  : 'header_node_start_time_en'
              "
            >
              {{ $t("gantt.startTime") }}
            </div>
            <div
              :class="
                currentLang == 'zh'
                  ? 'header_node_end_time'
                  : 'header_node_end_time_en'
              "
            >
              {{ $t("gantt.endTime") }}
            </div>
            <div v-if="checkList.work_hours" class="header_node_work_hours">
              {{ $t("gantt.workHours") }} ( {{ $t("gantt.total") }}
              {{ getAllWorkTime }} {{ $t("gantt.info4") }})
            </div>
            <div v-if="checkList.all_time" class="header_node_all_time">
              {{ $t("gantt.info3") }} ( {{ $t("gantt.total") }}
              {{ getAllTime }} {{ $t("gantt.info4") }})
            </div>
          </div>
        </div>
        <div style="width: 2px; background: rgb(240, 242, 245)"></div>
        <div class="right_group" style="flex: 1">
          <div style="width: 100%">
            <div id="container_group" ref="container_group"></div>
          </div>
        </div>
      </div>

      <div
        v-if="tableData.length == 0 && !is_show_chart"
        style="
          color: #a6a6a6;
          height: 100%;
          background: #fff;
          font-size: 18px !important;

          display: flex;

          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <div>
          <i
            class="el-icon-circle-plus"
            style="font-size: 200px; color: #e0e3e8; cursor: pointer"
            @click="beforeInsertRootNode"
          ></i>
        </div>
        <div>
          {{ $t("gantt.PleaseAdd") }}
        </div>
      </div>
      <!-- <el-empty
        v-if="tableData.length === 0 && is_show_chart"
        style="
          color: #a6a6a6;
          height: 100%;
          background: #fff;
          font-size: 18px !important;
        "
        :description="$t('gantt.info15')"
        :image="empty_img"
        :image-size="300"
      ></el-empty> -->
      <div
        v-show="tableData.length > 0 && !is_show_chart"
        id="node_tree"
        class="content_gantt"
      >
        <div class="left">
          <div class="bind_tree">
            <el-tree
              :key="table_key"
              :data="tableData"
              node-key="id"
              default-expand-all
              :draggable="draggable"
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              @node-drag-start="handleDragStart"
              @node-drag-enter="handleDragEnter"
              @node-drag-leave="handleDragLeave"
              @node-drag-over="handleDragOver"
              @node-drag-end="handleDragEnd"
              @node-drop="handleDrop"
              @node-expand="handleExpand"
              @node-collapse="handleCollapse"
            >
              <div
                :id="data.ganttItemUuid"
                slot-scope="{ node, data }"
                :class="{}"
                @contextmenu.prevent.stop="rightClick($event, data)"
              >
                <div class="flex-box">
                  <div
                    class="header_node_name"
                    :style="{ width: 200 - 18 * (node.level - 1) + 'PX' }"
                    style="text-align: left"
                  >
                    <el-tooltip
                      effect="dark"
                      placement="top"
                      :content="data.name"
                      :disabled="!isShowName"
                    >
                      <div style="width: 80%; overflow: hidden">
                        <div
                          v-if="data.is_adit_name === undefined"
                          data-type="name"
                          style="
                            width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color: #606266;
                            font-size: 14px;
                          "
                          @click.stop="show_drawer(data.nodeInfo)"
                          @dblclick.stop="doubleClick(data)"
                          @mouseenter="visibilityChange($event)"
                        >
                          <i
                            v-if="data.milestone === 'true'"
                            class="iconfont icon-qizhi"
                          ></i>
                          {{ data.name }}
                        </div>
                        <el-input
                          v-else
                          :ref="data.nodeKey + '_input'"
                          v-model="tempNodeName"
                          @blur="away(data)"
                        ></el-input>
                      </div>
                    </el-tooltip>
                  </div>
                  <span style="display: flex; align-items: center">
                    <i
                      v-limits-of-authority="'NODE_EDIT'"
                      class="iconfont icon-add"
                      style="color: rgb(48, 100, 143)"
                      @click.stop="beforeInsertChildNode(data)"
                    ></i>
                  </span>
                  <div v-if="checkList.key" class="header_node_key">
                    <span
                      style="
                        color: rgb(255, 195, 0);
                        font-weight: 700;
                        font-size: 14px;
                      "
                      @click.stop="nodeKeyLink(data)"
                      >{{ data.nodeKey }}</span
                    >
                    <i
                      style="
                        font-size: 14px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link"
                      @click.stop="nodeLink(data)"
                    ></i>
                  </div>
                  <div v-if="checkList.person" class="header_node_person">
                    <div
                      v-if="!data.is_edit_person"
                      v-limits-of-authority="'NODE_EDIT'"
                      style="font-size: 14px"
                      @click.stop="editPerson(data)"
                      :style="'background:' + get_style(data)"
                    >
                      {{ data.person }}
                    </div>
                    <el-select
                      v-else
                      :ref="data.nodeKey + '_person'"
                      v-model="assignee"
                      popper-class="user_select"
                      filterable
                      clearable
                      @blur="assignee_blur(data)"
                      @change="assignee_change(data)"
                    >
                      <el-option
                        v-for="opt in members"
                        v-show="!opt.deleted"
                        :key="opt.accountId"
                        :label="opt.nickname"
                        :value="opt.accountId"
                      >
                        <personal-avatar
                          class="avatar"
                          :avatar="opt.avatar"
                          :colorid="opt.accountId"
                          :nickname="opt.nickname"
                          :size="20"
                        >
                        </personal-avatar>
                        <span class="select_item">{{ opt.nickname }}</span>
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="checkList.status" class="header_node_status">
                    <status-select
                      v-model="data.statusName"
                      v-limits-of-authority="'NODE_EDIT'"
                      :node-key="data.nodeKey"
                      :status_type="
                        status[data.status]
                          ? status[data.status].statusCategory
                          : ''
                      "
                      @changeStatus="changeStatus"
                    ></status-select>
                  </div>
                  <div
                    :class="
                      currentLang == 'zh'
                        ? 'header_node_start_time'
                        : 'header_node_start_time_en'
                    "
                    :style="currentLang == 'zh' ? 'width:0.8rem' : 'width:1rem'"
                  >
                    <el-date-picker
                      v-model="data.startTime"
                      v-limits-of-authority="'NODE_EDIT'"
                      size="mini"
                      type="date"
                      :placeholder="$t('gantt.selectDate')"
                      align="right"
                      :style="
                        currentLang == 'zh'
                          ? 'font-size:14px'
                          : 'width:0.8rem;font-size:14px'
                      "
                      @change="timeChange(data)"
                      @click.native="$event.stopPropagation()"
                    >
                    </el-date-picker>
                    <!-- {{ data.startTime }} -->
                  </div>
                  <div
                    :class="
                      currentLang == 'zh'
                        ? 'header_node_end_time'
                        : 'header_node_end_time_en'
                    "
                    :style="currentLang == 'zh' ? 'width:0.8rem' : 'width:1rem'"
                  >
                    <el-date-picker
                      v-model="data.dueTime"
                      v-limits-of-authority="'NODE_EDIT'"
                      size="mini"
                      type="date"
                      :placeholder="$t('gantt.selectDate')"
                      align="right"
                      :class="judge_date(data.dueTime, data)"
                      :style="
                        currentLang == 'zh'
                          ? 'font-size:14px'
                          : 'width:0.8rem;font-size:14px'
                      "
                      @change="timeChange(data)"
                      @click.native="$event.stopPropagation()"
                    >
                    </el-date-picker>
                    <!-- {{ data.dueTime }} -->
                  </div>
                  <div
                    v-if="checkList.work_hours"
                    class="header_node_work_hours"
                  >
                    <div
                      v-limits-of-authority="'NODE_EDIT'"
                      class="edit_work_time"
                    >
                      <el-popover
                        :ref="'workpop-' + data.ganttItemUuid"
                        placement="right"
                        width="330"
                        trigger="click"
                      >
                        <div>
                          <el-form
                            hide-required-asterisk
                            :model="ruleForm"
                            :rules="ruleinput"
                          >
                            <el-form-item
                              :label="$t('gantt.workHours')"
                              label-width="80"
                              prop="addWorkProgressNum"
                            >
                              <div class="progress_dialog">
                                <el-input-number
                                  v-model="ruleForm.addWorkProgressNum"
                                  :min="0"
                                  :max="999"
                                  size="small"
                                  controls-position="right"
                                ></el-input-number>
                                <el-dropdown
                                  trigger="click"
                                  class="dropdown-link"
                                  style="width: 120px; margin-left: 10px"
                                  @command="handleWorkType"
                                >
                                  <span
                                    style="
                                      display: flex;
                                      width: 100%;
                                      white-space: nowrap;
                                      line-height: 50px;
                                      align-items: center;
                                      justify-content: space-around;
                                    "
                                    @click.stop
                                  >
                                    {{ progressworkType }}
                                    <i
                                      class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                      v-for="(
                                        item, index
                                      ) in progressTypeList.filter((a) => {
                                        return a.name !== progressworkType;
                                      })"
                                      :key="index"
                                      :command="item"
                                    >
                                      <div>
                                        {{ item.name }}
                                      </div>
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </div>
                            </el-form-item>
                            <el-button
                              type="primary"
                              style="float: right"
                              @click="changeProgressWorkButton(data)"
                              >{{ $t("btn.confirmBtn") }}</el-button
                            >
                          </el-form>
                        </div>
                        <div
                          slot="reference"
                          class="percent_all"
                          @click.stop="progressWorkShow(data)"
                        >
                          {{ helpShowNumber(data.planManHour / 8000)
                          }}{{ $t("gantt.info4") }}
                        </div>
                        <!-- <i
                          slot="reference"
                          class="iconfont icon-plan_time"
                          style="
                            width: 18px;
                            margin: 0 4px;
                            font-size: 18px;
                            color: rgb(128, 128, 128);
                          "
                          @click.stop="progressWorkShow(data)"
                        ></i> -->
                      </el-popover>
                    </div>
                  </div>
                  <div v-if="checkList.all_time" class="header_node_all_time">
                    <div v-limits-of-authority="'NODE_EDIT'" class="edit_time">
                      <el-popover
                        :ref="'popover-' + data.ganttItemUuid"
                        placement="right"
                        width="330"
                        trigger="click"
                      >
                        <div>
                          <el-form
                            hide-required-asterisk
                            :model="ruleForm"
                            :rules="ruleinput"
                          >
                            <el-form-item
                              :label="$t('gantt.info8')"
                              label-width="80"
                              prop="addProgressNum"
                            >
                              <div class="progress_dialog">
                                <el-input-number
                                  v-model="ruleForm.addProgressNum"
                                  :min="0"
                                  :max="999"
                                  size="small"
                                  controls-position="right"
                                ></el-input-number>
                                <el-dropdown
                                  trigger="click"
                                  class="dropdown-link"
                                  style="width: 120px; margin-left: 10px"
                                  @command="handleType"
                                >
                                  <span
                                    style="
                                      display: flex;
                                      width: 100%;
                                      white-space: nowrap;
                                      line-height: 50px;
                                      align-items: center;
                                      justify-content: space-around;
                                    "
                                    @click.stop
                                  >
                                    {{ progressType }}
                                    <i
                                      class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                      v-for="(
                                        item, index
                                      ) in progressTypeList.filter((a) => {
                                        return a.name !== progressType;
                                      })"
                                      :key="index"
                                      :command="item"
                                    >
                                      <div>
                                        {{ item.name }}
                                      </div>
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </div>
                            </el-form-item>
                            <el-button
                              type="primary"
                              style="float: right"
                              @click="addProgressByTimeButton"
                              >{{ $t("btn.confirmBtn") }}</el-button
                            >
                          </el-form>
                        </div>
                        <div
                          slot="reference"
                          class="edit_add_time"
                          @click.stop="progressShow(data)"
                        >
                          <div>
                            {{ helpShowNumber(data.manHour / 8000)
                            }}{{ $t("gantt.info4") }}
                          </div>
                        </div>
                        <!-- <img
                          slot="reference"
                          src="@/assets/img/addTime.png"
                          alt=""
                          style="width: 18px; margin: 0 4px"
                          @click.stop="progressShow(data)"
                        /> -->
                      </el-popover>
                      <img
                        src="@/assets/img/arrow.png"
                        alt=""
                        style="width: 16px; margin: 0 10px"
                        @click.stop="clickCalcTimeProgress(data)"
                      />
                      <div class="percent_all">
                        <el-input-number
                          v-model="data.progress"
                          :min="0"
                          :max="100"
                          size="mini"
                          controls-position="right"
                          style="font-size: 14px"
                          @click.native="$event.stopPropagation()"
                          @change="changeProgressButton(data)"
                        ></el-input-number>
                      </div>
                      <div style="font-size: 14px">&nbsp; %</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tree>
          </div>
        </div>
        <div class="resize" :title="$t('addAlert.collapse')"></div>
        <div
          v-show="!is_show_chart"
          ref="gantt_right"
          class="right"
          style="flex: 1"
        >
          <div style="width=100%;">
            <div id="container" ref="container"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 一个甘特图都没有的时候占位图 -->
    <default-show v-if="!hasGanttChart" @show_dialog="gant_add"></default-show>

    <!-- 弹窗部分 -->
    <el-dialog
      :title="$t('gantt.AddNode')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogFormVisible"
      width="25%"
    >
      <div class="add_nodes" style="margin-bottom: 20px">
        <div>
          <el-select
            v-model="add_nodes"
            multiple
            size="small"
            filterable
            collapse-tags
            remote
            :remote-method="get_add_nodes_options"
            :loading="add_nodes_options_loading"
            :popper-append-to-body="false"
            :placeholder="$t('gantt.PleaseEnterNodeID')"
            :reserve-keyword="true"
            @visible-change="changeSelectBugNodes"
            style="width: 100%; margin-top: 16px"
            popper-class="select-icon-height-auto-class relationOwnBug"
          >
            <el-option :value="'add'">
              <template #default>
                <div
                  @click.stop="newFile()"
                  style="
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    color: rgb(48, 100, 143);
                  "
                >
                  <span v-show="parentId">{{
                    $t("homeTopBar.newFileForm.newNode")
                  }}</span>
                  <span v-show="!parentId">{{
                    $t("homeTopBar.newFileForm.tab1")
                  }}</span>
                  +
                </div>
              </template>
            </el-option>
            <el-option
              v-for="item in add_nodes_options"
              :key="item.nodeKey"
              :label="`${item.nodeKey} ${item.topic}`"
              :value="item.nodeKey"
            >
              <select-icon :item="item"> </select-icon>
            </el-option>
          </el-select>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
          style="display: flex; flex-direction: row-reverse; padding-top: 40px"
        >
          <el-button
            type="primary"
            size="small"
            style="margin-left: 20px"
            @click="get_selected_nodes"
            >{{ $t("btn.addBtn") }}</el-button
          >
          <el-button @click="dialogFormVisible = false">{{
            $t("review.tip13")
          }}</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('review.tip11')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogDeleteGanttVisible"
      width="25%"
    >
      <div class="add_nodes">
        <div>
          {{ $t("gantt.confirmTheDeletion") }}
        </div>
        <div style="float: right; padding-bottom: 20px; padding-top: 30px">
          <el-button type="danger" @click="deleteSelectedGanttChart">{{
            $t("review.tip19")
          }}</el-button>
          <el-button @click="dialogDeleteGanttVisible = false">{{
            $t("review.tip13")
          }}</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('gantt.CreateGantt')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="addGanttChartDialog"
      width="25%"
    >
      <el-form
        ref="form_newGanttChart"
        :model="form_newGanttChart"
        :rules="rules"
      >
        <el-form-item
          :label="$t('gantt.Name')"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input
            v-model="form_newGanttChart.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('gantt.Desc')" :label-width="formLabelWidth">
          <el-input
            v-model="form_newGanttChart.desc"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addGanttChartDialog = false">{{
          $t("review.tip13")
        }}</el-button>
        <el-button type="primary" @click="submitForm('form_newGanttChart')">{{
          $t("review.tip14")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('gantt.GanttSettings')"
      width="35%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="addGanttSettingDialog"
      class="gantt_set_dialog"
    >
      <el-form
        ref="form_GanttSetting"
        :model="form_GanttSetting"
        :rules="rules"
      >
        <el-form-item
          :label="$t('gantt.Name')"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input
            v-model="form_GanttSetting.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('gantt.Desc')" :label-width="formLabelWidth">
          <el-input
            v-model="form_GanttSetting.desc"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('gantt.NotificationRules')"
          :label-width="formLabelWidth"
        >
          <div
            v-for="(depend_rule, index) in form_GanttSetting.selectRules"
            :key="index"
            style="display: flex; align-items: center"
          >
            <el-dropdown
              class="drop_depend"
              trigger="click"
              @command="(item) => handleCommandRule(item, index, depend_rule)"
            >
              <div
                class="el-dropdown-link"
                style="background: #fff; width: 100%"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="depend_rule.description"
                  placement="top"
                >
                  <span class="text-name">{{ depend_rule.description }}</span>
                </el-tooltip>
                <i
                  class="el-icon-arrow-down"
                  style="
                    position: absolute;
                    right: 6px;
                    top: 16px;
                    transform: translateY(-50%);
                  "
                ></i>
                <i
                  v-if="depend_rule.description !== ''"
                  class="el-icon-remove"
                  style="
                    color: red;
                    position: absolute;
                    right: -20px;
                    top: 16px;
                    transform: translateY(-50%);
                    cursor: pointer;
                  "
                  @click.stop="deleteDrop(index)"
                ></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in showDependRules"
                  :key="item.type"
                  :command="item"
                  >{{ item.description }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addGanttSettingDialog = false">{{
          $t("review.tip13")
        }}</el-button>
        <el-button type="primary" @click="submitForm('form_GanttSetting')">{{
          $t("review.tip14")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 过期弹窗 -->

    <!-- 固定位置工具部分 -->
    <div
      v-if="statusLegend === 'person' && !is_show_chart"
      :class="{ default_arrow: true, is_rotate: isShowDetailInfo }"
      style="color: #a6a6a6"
      @click="showDetailInfo"
    >
      <div v-if="isShowDetailInfo">
        <div
          v-for="value in showpaletteStatusObj"
          :key="value.person"
          style="display: flex; align-items: center"
        >
          <div style="padding-left: 10px; padding-right: 10px; width: 160px">
            {{
              statusLegend === "group"
                ? ""
                : value.person +
                  `(${helpShowNumber(value.allTime / 1000)}${$t(
                    "gantt.info4"
                  )})`
            }}
          </div>
          <div
            :style="{ background: value.color.all_color }"
            style="width: 10px; height: 10px"
          ></div>
          <div
            style="padding-left: 10px; padding-right: 6px"
            :style="{ color: value.color.all_color }"
          >
            {{ value.count }}
          </div>
        </div>
      </div>
      <img src="@/assets/img/arrowRotate.png" alt="" />
    </div>

    <div
      v-show="!is_show_chart && hasGanttChart && tableData.length !== 0"
      id="fix_scale_group"
      class="fix_scale_group"
      style="position: fixed; right: 85px; bottom: 30px"
      draggable="true"
      @dragend="enddrag"
    >
      <div
        class="fix_scale fix_scale_out"
        style="display: flex; align-items: center"
      >
        <i
          class="iconfont icon-dingwei"
          style="
            padding: 10px;
            color: rgb(21, 77, 168);
            background: #fff;
            cursor: pointer;
          "
          @click="dayLineCenter"
        ></i>
      </div>
      <div class="fix_scale fix_scale_in">
        <el-button
          icon="el-icon-zoom-in"
          style="
            padding: 10px;
            background: rgb(21, 77, 168);
            color: #fff;
            cursor: pointer;
          "
          @click="addScale"
        >
        </el-button>
      </div>
      <div class="fix_scale fix_scale_out">
        <el-button
          icon="el-icon-zoom-out"
          style="
            padding: 10px;
            background: rgb(21, 77, 168);
            color: #fff;
            cursor: pointer;
          "
          @click="reduceScale"
        ></el-button>
      </div>
    </div>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :is_show_name="is_show_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />

    <div
      v-if="isShowMenu"
      class="menu"
      :style="{ left: menuLeft + 'px', top: menuTop + 'px' }"
    >
      <right-menu
        :right-node="rightNode"
        @hiddenRightMenu="hiddenRightMenu"
        @updateMilestone="updateMilestone"
      />
    </div>
    <exportDialog
      ref="exportDialog"
      :gantt-chart-id="selectedGanttChart.ganttChartId"
    ></exportDialog>
    <importDialog
      ref="importDialog"
      :gantt-chart-id="selectedGanttChart.ganttChartId"
      @reLoadNodes="initGanttNodes"
    ></importDialog>

    <el-dialog
      :title="data_title"
      width="50%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogDataShow"
    >
      <el-table
        :stripe="false"
        :data="node_tableData"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
        style="width: 100%; position: relative"
        height="500"
      >
        <el-table-column prop="key" align="left" label="Key" width="160">
          <template slot-scope="scope">
            <span
              style="cursor: pointer; color: rgba(255, 195, 0, 1)"
              @click="rowClicked(scope.row)"
            >
              {{ scope.row.key }}
            </span>
            <i
              style="
                margin-left: 10px;
                cursor: pointer;
                color: rgb(48, 100, 143);
              "
              class="iconfont icon-node_link"
              @click="nodeLinkData(scope.row)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column :label="$t('board.chart.name')" show-overflow-tooltip>
          <template slot-scope="scoped">
            <p @mouseenter="visibilityChange($event)">
              {{ scoped.row.topic }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('nodeDetail.basicInfo.responsibility')"
          width="150"
        >
          <template slot-scope="scoped">
            <el-tooltip
              effect="dark"
              :content="scoped.row.assignee"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p @mouseenter="visibilityChange($event)">
                {{ scoped.row.assignee }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 新建文件 -->
    <el-dialog
      :visible.sync="is_dialog_visible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeHandler('newFileForm')"
    >
      <div slot="title">
        <span class="title-text">{{ $t("test.newFile.title") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="newFileForm"
          :model="newFileForm"
          :rules="newFileFormRules"
          hide-required-asterisk
        >
          <el-form-item prop="name">
            <el-input
              v-model="newFileForm.name"
              size="small"
              :placeholder="$t('test.newFile.place')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="type">
            <el-select
              v-model="newFileForm.type"
              :placeholder="$t('test.newFile.place2')"
              size="small"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in newFileForm.type_options"
                :key="index"
                :label="item.name"
                :value="item.fileTypeId"
                >{{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_dialog_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="createProgram_comfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 新建节点 -->
    <el-dialog
      :visible.sync="addNodeVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text">{{
          $t("homeTopBar.newFileForm.newNode")
        }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="newNodeForm"
          :model="newNodeForm"
          :rules="newNodeFormRules"
          hide-required-asterisk
        >
          <el-form-item prop="name">
            <el-input v-model="newNodeForm.name" size="small"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="addNodeVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="newNode_comfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 排期约束提示 -->
    <el-dialog
      :visible.sync="autoScheduleVisible"
      width="40%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text">{{ currentGanttSchedule.title }}</span>
      </div>
      <div class="dialog-content">
        <div class="schedule-warnning">
          <p>
            <i
              class="el-icon-warning-outline"
              style="color: #184fa9; font-size: 16px"
            ></i>
            <span>{{
              currentGanttSchedule.tag === "DOWN"
                ? $t("gantt.ScheduleFromTopTip")
                : $t("gantt.ScheduleFromTopBottomTip")
            }}</span>
          </p>
          <p>
            <span style="color: red">{{ $t("gantt.irreversible") }}</span>
          </p>
        </div>
        <p style="font-size: 16px">
          <span
            >{{ $t("gantt.ScheduleFromTip2") }}{{ currentGanttSchedule.title
            }}{{ $t("gantt.ScheduleFromTip3") }}</span
          >
        </p>
        <div class="compare">
          <div>
            <p>
              <span>
                {{
                  currentGanttSchedule.tag === "DOWN"
                    ? $t("gantt.BeforeScheduleFromTop")
                    : $t("gantt.BeforeScheduleFromBottom")
                }}
              </span>
            </p>
            <div>
              <img
                :src="currentGanttSchedule.tag === 'DOWN' ? tbefore : bbefore"
              />
            </div>
          </div>
          <div>
            <p>
              <span>
                {{
                  currentGanttSchedule.tag === "DOWN"
                    ? $t("gantt.AfterScheduleFromTop")
                    : $t("gantt.AfterScheduleFromBottom")
                }}</span
              >
            </p>
            <div>
              <img
                :src="currentGanttSchedule.tag === 'DOWN' ? tafter : bafter"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="info" @click="autoScheduleVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="autoScheduleConfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Graph, Shape, Label, Edge } from "@antv/x6";
import moment from "moment";
import { add_node } from "@/network/mindmap";
import {
  getAllGanttChart,
  addGanttChart,
  deleteGanttChart,
  insertToGantt,
  getGanttNode,
  getGanttNodeAndDepend,
  addGanttNodeAndDepend,
  deleteGanttNodeAndDepend,
  removeGanttNode,
  searchByKeys,
  changeAllTime, //单个节点时间修改
  ClearGanttOffsetTime, // 清空开始结束时间
  changeTimeList, //多个节点时间修改
  changeProgress,
  calcTimeProgress,
  changePosition, //修改节点上下位置
  addTimeProgress,
  addWorkTimeProgress,
  getPlannedWorkingHoursStatus,
  getPlannedWorkingHoursAssignee,
  getPlannedWorkingHoursTable,
  getPlannedWorkingHoursTime,
  postNodeList,
  deleteNodeList,
  get_file,
  getTimeLength, //获得甘特图起始结束时间
  getGanttRules, //获得甘特图允许的规则
  setSelectedGanttRules,
  getSelectedGanttRules,
  get_nodes_by_fuzzykey,
  getPayGantt, //甘特图是否开通付费
  autoSchedule,
  getBatchAnalyzeGanttStatus,
} from "@/network/gantt/index.js";
import DefaultShow from "@/components/dataChart";
import PersonalAvatar from "@/components/personal";
import exportDialog from "./components/exportDialog.vue";
import importDialog from "./components/importDialog.vue";
import {
  multiLayers_change_assignee,
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import { mapGetters } from "vuex";
import StatusSelect from "@/components/statusSelect";
import SockJS from "sockjs-client";
import Driver from "@/plugins/driver.js";
import Stomp from "stompjs";
// 引入引导框样式
import "@/plugins/driver.css";
import NodeStatusSelect from "@/components/mindmap/node_detail/NodeStatusSelect";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow";
import NodeReleaseInfoRow from "@/components/mindmap/node_detail/NodeReleaseInfoRow";
import selectIcon from "@/components/selectIcon";
import NodeDrawer from "@/components/nodeDrawer";
import { getFile, get_filetype, createNewFile } from "@/network/home/index.js";
import RightMenu from "./components/RightMenu.vue";
import tbefore from "@/assets/img/tbefore.png";
import tafter from "@/assets/img/tafter.png";
import bbefore from "@/assets/img/bbefore.png";
import bafter from "@/assets/img/bafter.png";
export default {
  name: "Gantt",
  components: {
    DefaultShow,
    StatusSelect,
    PersonalAvatar,
    NodeBaseInfoRow,
    NodeStatusSelect,
    ViewWorkflow,
    NodeReleaseInfoRow,
    RightMenu,
    NodeDrawer,
    exportDialog,
    importDialog,
    selectIcon,
  },
  data() {
    return {
      tbefore,
      tafter,
      bbefore,
      bafter,
      //1.顶部/全局
      loading: true,
      right_scrollLeft: "",
      right_scrollTop: "",
      hasPay: false,
      hasGanttChart: false,
      ganttChartsList: [],
      selectedGanttChart: {},
      top_options: {
        select_model: "",
        name: "",
        status: "",
        show_zoom: this.$t("gantt.byMonth"),
        show_legend: this.$t("gantt.byGroup"),
        left_day: 10,
        description: "",
        desc: "",
        startDateTime: "",
        endDateTime: "",
      },
      cur_gant_id: "",
      legendList: [
        {
          legendName: this.$t("gantt.byGroup"),
          legendId: 1,
          tag: "group",
        },
        {
          legendName: this.$t("gantt.byResponsible"),
          legendId: 3,
          tag: "person",
        },
      ],
      cellList: [
        {
          title: this.$t("gantt.byMonth"),
          tag: "byMonth",
        },
        {
          title: this.$t("gantt.byQuarte"),
          tag: "byQuarter",
        },
        {
          title: this.$t("gantt.byYear"),
          tag: "byYear",
        },
      ],
      autoScheduleList: [
        {
          title: this.$t("gantt.ScheduleFromTop"),
          tag: "DOWN",
        },
        {
          title: this.$t("gantt.ScheduleFromBottom"),
          tag: "UP",
        },
      ],
      statusLegend: "group",
      empty_img: require("@/assets/img/nothing2.png"),
      statusCell: "byMonth", //按月来还是按年来
      beforeTableData: [],
      tableData: [],
      tableData2: [], //tableData副本
      startTime: "2023-01-01",
      formLabelWidth: "100px",
      ganttList: [], //flat成数组的树，用于渲染graph条纹
      globalScale: 1.0, //全局比例尺
      globalScale_v: 1.0, //全局比例尺
      allowHandle: true, //websocket禁止功能
      show_top: true, //收起展开
      //2.el-tree部分
      draggable: true,
      isShowTableList: false,
      checkList: {
        person: true,
        status: true,
        all_time: true,
        key: true,
        work_hours: true,
      },
      is_show_chart: false,
      assignee: "",
      tempNodeName: "",
      personFilter: [], //责任人筛选列表
      statusFilter: [], //状态筛选列表

      //3.graph部分

      tableWidth: 8000,
      isShowDetailInfo: false,
      ganttItemHeight: 30, //单个甘特图条高度
      ganttDragItemHeight: 18, //拖拽条的高度
      startLen: 40, //初始距离x轴距离
      gridLen: 20, //一个的长度
      palette: [], //调色盘,
      cur_palette: 0, //调色盘指针
      paletteStatusObj: {}, //调色盘统计
      relationList: [],
      beforeDeleteList: [],
      nowLeft: 0, // 当日线离最左的距离

      //4.弹窗部分

      addGanttChartDialog: false,
      addGanttSettingDialog: false,
      dialogFormVisible: false,
      dialogDeleteGanttVisible: false,
      table_key: 0,
      form_newGanttChart: {
        name: "",
        desc: "",
      },
      form_GanttSetting: {
        name: "",
        desc: "",
        selectRules: [
          {
            name: "",
            description: "",
          },
        ],
      },
      ruleForm: {
        addWorkProgressNum: 0,
        addProgressNum: 0,
      },
      ruleinput: {
        addWorkProgressNum: [
          {
            required: true,
            message: this.$t("addEditCopy.num"),
            trigger: "blur",
          },
        ],
        addProgressNum: [
          {
            required: true,
            message: this.$t("addEditCopy.num"),
            trigger: "blur",
          },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.name"),
            trigger: "blur",
          },
        ],
      },
      setDependRules: [],
      add_nodes: [], // 添加节点用的
      add_nodes_options: [],
      add_nodes_options_loading: false,
      progressType: this.$t("gantt.info6"),
      progressworkType: this.$t("gantt.info6"),
      progressTypeList: [
        {
          name: this.$t("gantt.info6"),
          type: "hour",
        },
        {
          name: this.$t("gantt.info4"),
          type: "hour",
        },
        {
          name: this.$t("gantt.info5"),
          type: "hour",
        },
      ],
      progressData: {},
      //抽屉相关
      drawer: false, //抽屉
      drawer_loading: false,
      file_icon: "",
      file_icon_name: "",
      is_show_name: true,
      current_node: {},
      workflow_dialog_visible: false,
      node_name_span: true,
      input_name: "",

      //5.工具函数

      parentId: "", //节点嵌套用的
      childIndex: 1,
      childLayer: 1,
      throttleTimer: {}, //节流用
      isShowTooltip: false,
      isShowName: false,
      isShowMenu: false, //右击菜单
      menuTop: 0,
      menuLeft: 0,
      rightNode: {}, //右键点击的node
      delConfirm: false,

      newDescription: "",
      itemId: "",
      flag: false, //判断是否高亮过

      checkAllPerson: false,
      isIndeterminatePerson: false,
      checkAllStatus: false,
      isIndeterminateStatus: false,

      //图表
      myChart_bar1: "",
      myChart_bar2: "",
      myChart_bar4: "",
      tablechartData: [],
      tableStatusAll: [],
      tableshow: false,
      data_title: "",
      dialogDataShow: false,
      node_tableData: [],
      all_node_tableData: [],
      node_tableData_hang: {},
      node_tableData_lie: {},

      show_person_var: "",
      is_dialog_visible: false,
      addNodeVisible: false,
      autoScheduleVisible: false,
      // 创建文件弹窗
      newFileForm: {
        name: "",
        type: "",
        type_options: [],
      },
      //  创建节点弹窗
      newNodeForm: {
        name: "",
      },
      // 创建节点弹窗的规则
      newFileFormRules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
      },
      // 创建文件弹窗的规则
      newNodeFormRules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
      },
      addRoot: false,
      currentGanttSchedule: {},
      // 汇总表格
      activeNames: [],
      table1Check: [],
      allGanttCurrentData: [],
      currentMaxNum: 0,
      isStatusShowTooltip: false,
      table1pages: {
        current: 0,
        total: 0,
      },
      lockSelect: false,
      is_show_important_route: false,
    };
  },
  watch: {
    hasGanttChart: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.bindScroller();
            this.bindMoveLine();
          });
        }
      },
      immediate: true,
    },
    checkList: {
      handler(newVal, oldVal) {
        localStorage.setItem("checkList_store", JSON.stringify(newVal));
      },
      deep: true,
    },
    cur_gant_id: function () {
      // 存入sessionStorage
      sessionStorage.setItem("ganttId", this.cur_gant_id);
      this.$router.push({
        name: "gantt",
        params: { cur_gant_id: this.cur_gant_id },
      });
    },
  },
  computed: {
    ...mapGetters([
      "user_list",
      "status",
      "token",
      "userAccountId",
      "fileType",
      "project_user_list",
      "jobAuthorities",
    ]),
    ganttDragTop() {
      return (this.ganttItemHeight - this.ganttDragItemHeight) / 2;
    },
    dayDependLen() {
      return 20;
    },
    showDependRules() {
      let result = this.setDependRules;
      this.form_GanttSetting.selectRules.forEach((item) => {
        result = result.filter((child) => {
          return child.name != item.name;
        });
      });
      return result;
    },
    textFixFont() {
      return 1 / this.globalScale;
    },
    showpaletteStatusObj() {
      let arr = []; //定义数组
      let temp = "";
      for (let i in this.paletteStatusObj) {
        arr.push(this.paletteStatusObj[i]);
        if (this.paletteStatusObj[i].person === "-") {
          temp = this.paletteStatusObj[i];
        }
      }
      arr.sort((a, b) => {
        return parseInt(b.allTime) - parseInt(a.allTime);
      });
      let showArr = arr.filter((item) => {
        return item.person !== "-";
      });
      if (temp) {
        temp.person = this.$t("gantt.NoResponsible");
        showArr.push(temp);
      }
      return showArr;
    },
    personSet() {
      let set = new Set();
      for (const data of this.beforeTableData) {
        set.add(data.person);
      }
      return Array.from(set).map((item) => {
        if (item === "-") {
          return {
            value: "-",
            label: this.$t("gantt.NoResponsible"),
          };
        } else {
          return {
            value: item,
            label: item,
          };
        }
      });
    },
    statusSet() {
      let result = [];
      for (const s in this.status) {
        result.push(this.status[s]);
      }
      return result;
    },
    getAllWorkTime() {
      let alltime = 0;
      this.ganttList.map((item) => {
        alltime += item.planManHour;
      });
      return this.helpShowNumber(alltime / 8000);
    },
    getAllTime() {
      let allHour = this.ganttList.reduce((pre, cur) => {
        return pre + cur.manHour;
      }, 0);
      return this.helpShowNumber(allHour / 8000);
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
    currentLang() {
      return localStorage.getItem("lang") || "zh" == "zh";
    },
  },
  created() {
    //   if (this.$route.params.cur_gant_id && this.$route.params.cur_gant_id != "enterOwn") {
    //     sessionStorage.setItem("ganttId", this.$route.params.cur_gant_id);
    //     const cur_gant_id = sessionStorage.getItem("ganttId");
    //   }
    document.addEventListener("click", (e) => {
      if (e.target.className !== "menu-item operation-text") {
        this.isShowMenu = false;
      }
    });
    //右击
    document.addEventListener("mousedown", (e) => {
      const { button } = e;
      if (button === 2) {
        this.isShowMenu = false;
      }
    });
  },
  beforeMount() {
    this.paletteInit(); //调色盘初始化
    this.getStatusName();
    this.registerEdgeAndNode();
    this.initRuleList();
    this.judgePayForGantt();
    this.checkListInit();
  },
  mounted() {
    this.initAll();
    this.guideListener();
  },
  beforeDestroy() {
    this.closeWebsocket();
  },

  methods: {
    async important_route() {
      if (this.is_show_important_route) {
        for (let edge of this.graph.getEdges()) {
          if (edge.store.data.source.cell && edge.store.data.source.cell) {
            edge.attr("line/stroke", "#a6a6a6");
          }
        }
        this.is_show_important_route = false;
        return;
      }
      let res = await getGanttNodeAndDepend(
        this.get_pid(),
        this.is_show_chart
          ? this.table1Check
          : [this.selectedGanttChart.ganttChartId]
      );

      this.relationList = res.relationList || [];
      this.is_show_important_route = true;
      function findMaxEndTimePath(node, path = []) {
        // 将当前节点的id添加到路径中
        path.push(node.task_id);
        node.children ? node.children : (node.children = []);
        // 如果当前节点没有子节点，返回当前路径和最大end_time
        if (node.children.length === 0) {
          return { path, endTime: node.end_time };
        }

        let maxPath = { path: [], endTime: -Infinity };

        // 遍历所有子节点，找到最大end_time的路径
        for (const child of node.children) {
          const { path: childPath, endTime: childEndTime } = findMaxEndTimePath(
            child,
            [...path]
          );
          if (childEndTime > maxPath.endTime) {
            maxPath = { path: childPath, endTime: childEndTime };
          }
        }

        // 返回找到的最大end_time路径
        return maxPath;
      }
      let id_key = {};
      for (let item of this.tableData) {
        id_key[item.ganttItemUuid] = item.ganttItemUuid;
      }
      let tas = {};
      for (let item of this.relationList) {
        if (!(id_key[item.secondItemUuid] in tas)) {
          tas[id_key[item.secondItemUuid]] = [];
        }
        tas[id_key[item.secondItemUuid]].push(id_key[item.firstItemUuid]);
      }

      const tasks = [];
      for (let item of this.tableData) {
        tasks.push({
          task_id: id_key[item.ganttItemUuid],
          start_time: new Date(item.startTime),
          end_time: new Date(item.dueTime),
          dependencies: tas[id_key[item.ganttItemUuid]]
            ? tas[id_key[item.ganttItemUuid]]
            : [],
        });
      }

      const taskMap = tasks.reduce((map, task) => {
        map[task.task_id] = task;
        return map;
      }, {});
      const tree = [];
      function calculateTimes(node) {
        // 初始化当前节点的最早开始时间和最晚结束时间为节点自身的时间
        let earliestStartTime = node.start_time;
        let latestEndTime = node.end_time;

        // 如果有子节点，则递归计算子树的时间
        if (node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            const {
              earliestStartTime: childEarliest,
              latestEndTime: childLatest,
            } = calculateTimes(child);

            // 更新当前节点的最早开始时间和最晚结束时间
            if (childEarliest < earliestStartTime) {
              earliestStartTime = childEarliest;
            }
            if (childLatest > latestEndTime) {
              latestEndTime = childLatest;
            }
          });
        }

        // 返回当前子树的最早开始时间和最晚结束时间
        return { earliestStartTime, latestEndTime };
      }
      tasks.forEach((task) => {
        if (task.dependencies.length === 0) {
          // 没有依赖的任务，直接加入第一层
          tree.push(task);
        } else {
          // 递归地找到父节点，并将当前任务加入父节点的子任务列表
          const findParent = (parentId) => {
            const parent = taskMap[parentId];
            if (parent) {
              if (!parent.children) {
                parent.children = [];
              }
              parent.children.push(task);
            }
          };

          task.dependencies.forEach(findParent);
        }
      });

      let max_time = 0;
      let max_tree = null;
      tree.forEach((rootNode) => {
        if (rootNode.children) {
          const { earliestStartTime, latestEndTime } = calculateTimes(rootNode);
          if (max_time < latestEndTime - earliestStartTime) {
            max_time = latestEndTime - earliestStartTime;
            max_tree = rootNode;
          }

          // 可以选择将计算出的时间附加到节点对象上，以便后续使用
          rootNode.st = earliestStartTime;
          rootNode.en = latestEndTime;
        }
      });
      if (!max_tree) {
        return;
      }
      let import_rout = {};
      const result = findMaxEndTimePath(max_tree).path;

      for (let i = 0; i < result.length - 1; i++) {
        import_rout[result[i]] = result[i + 1];
      }
      for (let edge of this.graph.getEdges()) {
        if (edge.store.data.source.cell && edge.store.data.source.cell) {
          if (edge.store.data.source.cell in import_rout) {
            if (
              import_rout[edge.store.data.source.cell] ==
              edge.store.data.target.cell
            ) {
              edge.attr("line/stroke", "red");
            }
          }
        }
      }
    },
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("2") == -1) {
          this.activeNames.push("2");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("2") !== -1) {
        this.activeNames.splice(this.activeNames.indexOf("2"), 1);
      }
    },
    table1pagesHandleCurrentChange(current) {
      this.getBatchAnalyzeGanttStatus(current);
    },
    handleSelectionChange(item) {
      if (this.lockSelect) return;
      const has = this.allGanttCurrentData.filter((single) => {
        let flag = false;
        item.forEach((checked) => {
          if (checked.ganttChartUuid === single.ganttChartUuid) {
            flag = true;
          }
        });
        if (flag) {
          return true;
        }
      });
      const nohas = this.allGanttCurrentData.filter((single) => {
        let flag = false;
        item.forEach((checked) => {
          if (checked.ganttChartUuid === single.ganttChartUuid) {
            flag = true;
          }
        });
        if (!flag) {
          return true;
        }
      });
      nohas.forEach((item) => {
        if (this.table1Check.indexOf(item.ganttChartUuid) !== -1) {
          this.table1Check.splice(
            this.table1Check.indexOf(item.ganttChartUuid),
            1
          );
        }
      });
      has.forEach((item) => {
        if (this.table1Check.indexOf(item.ganttChartUuid) === -1) {
          this.table1Check.push(item.ganttChartUuid);
        }
      });
      if (this.table1Check.length) {
        this.getChartData();
        this.initGanttNodes();
      }
    },
    handleselectAll(item) {
      let ids = [];
      if (item.length) {
        ids = this.ganttChartsList.map((gantt) => {
          return gantt.ganttChartId;
        });
      } else {
        ids = [];
      }
      this.table1Check = ids;
      this.getChartData();
    },
    visibilityChange(event) {
      const ev = event.target.children[0];
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight && this.use) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isStatusShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isStatusShowTooltip = false;
      }
    },
    getCount(all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return total;
    },
    getWidth(item, all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return (item.count / total) * 100 + "%";
    },
    getWrapWidth(all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return (total / this.currentMaxNum) * 100 + "%";
    },
    createProgram_comfirm() {
      this.$refs["newFileForm"].validate(async (valid) => {
        if (valid) {
          //新建文件 确定
          try {
            let res = await createNewFile(
              //创建文件
              this.get_pid(),
              this.newFileForm.name,
              this.newFileForm.type
            );
            this.is_dialog_visible = false;
            this.$store.commit("SET_FILE_KEY", res);
            this.$store.commit("SET_FILETYPE_ID", this.newFileForm.type);
            this.$store.commit("SET_MINDMAP_NAME", this.newFileForm.name);
          } catch (error) {
            console.log(error);
            return;
          }
          this.$message({
            message: this.$t("homeTopBar.newFileForm.message"),
            type: "success",
            duration: 5 * 1000,
          });
          let res = await getFile(
            //获取文件内容
            this.get_pid(),
            this.$store.getters.file_key
          );
          this.$store.commit("SET_FILE_ID", res.data[0].fileId);
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_MAP_PERMISSION", "owner");
          if (this.parentId === "") {
            this.insertRootNodeToChart([res.data[0].key]);
          } else {
            this.insertChildNodeToChart([res.data[0].key]);
          }
          const new_route = this.$router.resolve(
            `/${this.get_pid()}/myMind/${this.$store.getters.file_key}`
          );
          window.open(new_route.href, "_blank");
          this.newFileForm.name = "";
          this.newFileForm.type = "";
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    newNode_comfirm() {
      this.$refs["newNodeForm"].validate(async (valid) => {
        if (valid) {
          const res = await get_file(this.get_pid(), this.parentNode.fileId);

          let newNode = {
            fileKey: res.key,
            parentid: this.parentNode.id,
            dataList: [
              {
                name: this.newNodeForm.name,
              },
            ],
          };
          add_node(this.get_pid(), newNode).then((r) => {
            this.insertChildNodeToChart([r[0].key]);
            this.addNodeVisible = false;
            this.$message({
              message: this.$t("homeTopBar.newFileForm.message"),
              type: "success",
              duration: 5 * 1000,
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeHandler(form) {
      this.$refs[form].resetFields();
    },
    // 新建文件弹窗
    newFile() {
      if (this.parentId) {
        this.addNodeVisible = true;
      } else {
        get_filetype(this.get_pid()).then((res) => {
          this.newFileForm.type_options = res;
          this.is_dialog_visible = true;
        });
      }
    },
    // 自动排期
    autoSchedule(ganttScheduleType) {
      this.currentGanttSchedule = ganttScheduleType;
      this.autoScheduleVisible = true;
    },
    autoScheduleConfirm() {
      const params = {
        projectId: this.get_pid(),
        ganttChartId: this.selectedGanttChart.ganttChartId,
        data: {
          ganttScheduleType: this.currentGanttSchedule.tag,
        },
      };
      autoSchedule(params).then((res) => {
        this.initAll();
        this.autoScheduleVisible = false;
      });
    },
    judge_date(date, status) {
      let status_cat = !(this.$store.getters.status[status.status]
        ? this.$store.getters.status[status.status].statusCategory == "DONE"
        : false);
      const inputDate = new Date(date);
      const now = new Date();

      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (
        ((inputDate.getTime() === today.getTime() && status_cat) ||
          (inputDate.getTime() < today.getTime() && status_cat)) &&
        inputDate.getTime() > 0
      ) {
        return "date-picker-red-border";
      } else if (inputDate.getTime() === tomorrow.getTime() && status_cat) {
        return "date-picker-yellow-border";
      } else {
        return "class";
      }
    },
    tablechartData_click(row, column, cell, event) {
      if (
        (row.respeople == "合计" || row.respeople == "total") &&
        (column.label == "合计" || column.label == "total")
      ) {
        this.node_tableData = [];
        return;
      }

      if (row.respeople == "合计" || row.respeople == "total") {
        this.data_title = "";
        let data = [];
        this.beforeTableData.forEach((item) => {
          if (item.statusName == column.label) {
            data.push({
              assignee: item.person == "-" ? "null" : item.person,
              topic: item.name,
              key: item.nodeKey,
              id: item.nodeInfo.id,
              fileTypeId: item.nodeInfo.fileTypeId,
              fileId: item.nodeInfo.fileId,
            });
          }
        });
        this.node_tableData = data;
        this.dialogDataShow = true;

        return;
      }

      if (column.label == "合计" || column.label == "total") {
        this.data_title = "";
        let data = [];
        this.beforeTableData.forEach((item) => {
          if (row.respeople == "null") {
            if (item.person == "-") {
              data.push({
                assignee: item.person == "-" ? "null" : item.person,
                topic: item.name,
                key: item.nodeKey,
                id: item.nodeInfo.id,
                fileTypeId: item.nodeInfo.fileTypeId,
                fileId: item.nodeInfo.fileId,
              });
            }
          } else {
            if (item.person == row.respeople) {
              data.push({
                assignee: item.person == "-" ? "null" : item.person,
                topic: item.name,
                key: item.nodeKey,
                id: item.nodeInfo.id,
                fileTypeId: item.nodeInfo.fileTypeId,
                fileId: item.nodeInfo.fileId,
              });
            }
          }
        });
        this.node_tableData = data;
        this.dialogDataShow = true;

        return;
      }
      this.data_title = column.label + " - " + row.respeople;
      let data = [];
      let data2 = [];
      this.beforeTableData.forEach((item) => {
        if (item.statusName == column.label) {
          data.push({
            assignee: item.person == "-" ? "null" : item.person,
            topic: item.name,
            key: item.nodeKey,
            id: item.nodeInfo.id,
            fileTypeId: item.nodeInfo.fileTypeId,
            fileId: item.nodeInfo.fileId,
          });
        }
      });
      data.forEach((item) => {
        if (item.assignee == row.respeople) {
          data2.push(item);
        }
      });
      this.node_tableData = data2;
      this.dialogDataShow = true;
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
    nodeLinkData(row) {
      get_file(this.get_pid(), row.fileId).then((ress) => {
        getFile(this.get_pid(), ress.key)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", row.fileId);
            this.$store.commit("SET_FILE_KEY", ress.key);
            // this.$store.commit("SET_MINDMAP_NAME", row.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", row.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${ress.key}`,
              query: {
                node_id: row.id,
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      //第一列的背景色
      if (columnIndex == 0) {
        return "background:#e8eff7;color:black;border-left:1px solid #e8eff7;";
      }
      if (column.label == "合计" || column.label == "total") {
        return "background:#5470c6;color:white;";
      }
      if (row.respeople == "合计" || row.respeople == "total") {
        return "background:#5470c6;color:white;";
      }
    },
    enddrag(a, b) {
      document.getElementById("fix_scale_group").style.top =
        a.pageY - 25 + "px";
      document.getElementById("fix_scale_group").style.left =
        a.pageX - 25 + "px";
      document.getElementById("fix_scale_group").style.bottom =
        a.pageY + 25 + "px";
      document.getElementById("fix_scale_group").style.right =
        a.pageX + 25 + "px";
    },
    debounce(fn, delay) {
      let timer = null;
      return function () {
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
        timer = setTimeout(() => {
          fn.resize();
        }, delay);
      };
    },
    setWidth(data) {
      return 100;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [this.$t("gantt.chart.title.title8")];
      let count = 0;
      for (const key in data[0]) {
        count++;
      }
      for (let i = 0; i < count - 1; i++) {
        sums.push(0);
      }
      data.forEach((item) => {
        columns.forEach((column, columnIndex) => {
          const key = column.property;
          if (key !== "respeople") {
            sums[columnIndex] += item[key];
          }
        });
      });
      const sum = sums.slice(1, -1).reduce((acc, val) => acc + val, 0);
      sums[sums.length - 1] += sum;
      return sums;
    },
    SetWorkingHoursRatio(data) {
      return data.progress;
    },
    closeAllchart() {
      this.closestatus_chart();
      this.closeresponsible_chart();
      this.closeresponsible_column_chart();
    },
    closestatus_chart() {
      this.$nextTick(() => {
        if (this.myChart_bar1) {
          this.myChart_bar1.dispose();
        }
      });
    },
    closeresponsible_chart() {
      this.$nextTick(() => {
        if (this.myChart_bar2) {
          this.myChart_bar2.dispose();
        }
      });
    },
    closeresponsible_column_chart() {
      this.$nextTick(() => {
        if (this.myChart_bar4) {
          this.myChart_bar4.dispose();
        }
      });
    },
    status_chart(list) {
      this.myChart_bar1 = this.$echarts.init(document.getElementById("bar1"));
      let option = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -200,
            type: "pie",
            minAngle: 5,
            data: list,
            // label: {
            //   show: false,
            // },
            // itemStyle: {
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%", // 修改formatter，使用\n进行换行
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("gantt.chart.title.title1"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
            fontSize: 18,
          },
        },
      };
      this.myChart_bar1.setOption(option);
      this.myChart_bar1.on("click", (params) => {
        let res_data = [];
        this.beforeTableData.forEach((item) => {
          if (item.statusName == params.name) {
            res_data.push({
              assignee: item.person == "-" ? "null" : item.person,
              topic: item.name,
              key: item.nodeKey,
              id: item.nodeInfo.id,
              fileTypeId: item.nodeInfo.fileTypeId,
              fileId: item.nodeInfo.fileId,
            });
          }
        });

        // this.all_node_tableData=res_data
        this.node_tableData = res_data;
        // this.current_page=1
        this.data_title =
          this.$t("gantt.chart.title.title1") + "  " + params.name;
        this.dialogDataShow = true;
      });
      let resize_handler = this.debounce(this.myChart_bar1, 500);
      window.addEventListener("resize", resize_handler);
    },
    responsible_chart(list) {
      this.myChart_bar2 = this.$echarts.init(document.getElementById("bar2"));
      let option = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -200,
            type: "pie",
            minAngle: 5,
            data: list,
            // label: {
            //   show: false,
            // },
            // itemStyle: {
            //   borderColor: "#fff",
            //   borderWidth: 2,
            // },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}:{c}\n{d}%", // 修改formatter，使用\n进行换行
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
              length: 20, // 扇形边上的线条长度
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("gantt.chart.title.title2"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
            fontSize: 18,
          },
        },
      };
      this.myChart_bar2.setOption(option);
      this.myChart_bar2.on("click", (params) => {
        let res_data = [];
        if (params.name == "null") {
          this.beforeTableData.forEach((item) => {
            if (item.person == "-") {
              res_data.push({
                assignee: item.person == "-" ? "null" : item.person,
                topic: item.name,
                key: item.nodeKey,
                id: item.nodeInfo.id,
                fileTypeId: item.nodeInfo.fileTypeId,
                fileId: item.nodeInfo.fileId,
              });
            }
          });
        } else {
          this.beforeTableData.forEach((item) => {
            if (item.person == params.name) {
              res_data.push({
                assignee: item.person == "-" ? "null" : item.person,
                topic: item.name,
                key: item.nodeKey,
                id: item.nodeInfo.id,
                fileTypeId: item.nodeInfo.fileTypeId,
                fileId: item.nodeInfo.fileId,
              });
            }
          });
        }

        // this.all_node_tableData=res_data
        this.node_tableData = res_data;
        // this.current_page=1
        this.data_title =
          this.$t("gantt.chart.title.title2") + "  " + params.name;
        this.dialogDataShow = true;
      });
      let resize_handler = this.debounce(this.myChart_bar2, 500);
      window.addEventListener("resize", resize_handler);
    },
    responsible_column_chart(list) {
      let self = this;
      let namelist = [];
      let dataplanlist = [];
      let datareallist = [];
      list.forEach((item) => namelist.push(item.name));
      list.map((item) =>
        dataplanlist.push(this.helpShowNumber(item.planManHour / 8000))
      );
      list.map((item) =>
        datareallist.push(this.helpShowNumber(item.manHour / 8000))
      );
      this.myChart_bar4 = this.$echarts.init(document.getElementById("bar4"));
      let option = {
        title: {
          text: this.$t("gantt.chart.title.title3"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
            fontSize: 18,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            let result = params[0].name + "<br>";
            for (let i = 0, l = params.length; i < l; i++) {
              result +=
                '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
                params[i].color +
                '"></span>';
              result += params[i].seriesName + ": " + params[i].value + "<br>";
            }

            if (params.length >= 2) {
              let differenceValue = params[1].value - params[0].value;
              result +=
                '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgb(81, 146, 255)"></span>';
              result +=
                self.$t("gantt.chart.title.title7") +
                ": " +
                differenceValue.toFixed(1) +
                "<br>";
            }

            return result;
          },
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "top",
          itemGap: 20,
          itemWidth: 30,
        },
        grid: {
          left: "3%",
          right: "15%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: namelist,
          name: this.$t("gantt.chart.title.title2"),
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          name: this.$t("gantt.chart.title.title6"),
        },
        series: [
          {
            name: this.$t("gantt.workHours"),
            type: "bar",
            data: dataplanlist,
          },
          {
            name: this.$t("gantt.chart.title.title5"),
            type: "bar",
            data: datareallist,
          },
        ],
      };
      this.myChart_bar4.setOption(option);
      let resize_handler = this.debounce(this.myChart_bar4, 500);
      window.addEventListener("resize", resize_handler);
    },
    // 导入弹窗展示
    importFn(params) {
      if (params === "import") {
        this.$refs["importDialog"].showDialog();
      }
    },
    // 导出方法
    exportFn(params) {
      if (params === "excel") {
        this.$refs["exportDialog"].showDialog();
      }
    },
    // 重新获取数据
    before_close_drawer() {
      this.drawer = false;
      this.initAll();
      this.guideListener();
    },
    // http://localhost/53b06625-370d-499e-9da7-90e147c38a3c/home/gantt?itemId=c3fb11aa-bea7-4351-9efb-a1cf374dec1c&chartId=93842905-3134-424e-8eae-e6930256f6c1
    //1.顶部/全局---------------------------------------------------------
    async initAll() {
      // this.chartId = this.$route.query.chartId || null;
      // this.itemId = this.$route.query.itemId || null;

      if (this.$route.query.chartId) {
        sessionStorage.setItem("ganttId", this.$route.query.chartId);
      }
      if (this.$route.params.cur_gant_id) {
        sessionStorage.setItem("ganttId", this.$route.params.cur_gant_id);
      }
      const ganttId = sessionStorage.getItem("ganttId");
      this.chartId = ganttId || null;
      this.itemId = this.$route.query.itemId || null;
      await this.initGanttChartsList(this.chartId); //获取甘特图表格列表
      this.initWebsocket();
      this.initDeleteAddEventListener();
    },
    //检测甘特图是否开通付费
    judgePayForGantt() {
      getPayGantt(this.get_pid()).then((res) => {
        this.hasPay = res || false;
      });
    },
    //初始化甘特图表格
    async initGanttChartsList(keep_ganttChartId) {
      await getAllGanttChart(this.get_pid())
        .then((res) => {
          this.ganttChartsList = res;
          if (this.ganttChartsList.length && this.ganttChartsList.length > 0) {
            this.hasGanttChart = true;
            let tempFlag = false;
            if (this.selectedGanttChart && keep_ganttChartId) {
              this.ganttChartsList.forEach((item) => {
                if (item.ganttChartId === keep_ganttChartId) {
                  tempFlag = true;
                  this.selectedGanttChart = item;
                  this.cur_gant_id = this.selectedGanttChart.ganttChartId;
                }
              });
            }
            // TODO
            if (!tempFlag) {
              this.selectedGanttChart = this.ganttChartsList[0];
              this.cur_gant_id = this.selectedGanttChart.ganttChartId;
            }
            return true;
          } else {
            this.loading = false;
            this.hasGanttChart = false;
          }
          return false;
        })
        .then((flag) => {
          if (flag) {
            this.initGanttNodes();
            // this.change_description();
          }
        });
    },
    //初始化甘特图节点
    async initGanttNodes(notReRender) {
      if (notReRender) {
        return;
      }
      if (!this.selectedGanttChart) {
        return;
      }
      await getGanttNodeAndDepend(
        this.get_pid(),
        this.is_show_chart
          ? this.table1Check
          : [this.selectedGanttChart.ganttChartId]
      ).then((res) => {
        this.beforeTableData = res.itemList || [];
        this.beforeTableData.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        this.relationList = res.relationList || [];
        this.change_description();
      });
      if (this.beforeTableData.length) {
        await this.tableDataToShow();
      } else {
        this.tableData = [];
      }
      this.handleCommandFilter();

      // setTimeout(() => {
      //   this.renderGantt();
      // }, 400);
    },
    //tableData转化
    async tableDataToShow() {
      if (this.beforeTableData.length && this.beforeTableData.length > 0) {
        let tempObj = {};
        let tempArr = [];
        let nodeArr = [];
        let nodeKeyObj = {};
        const dep_root_id = (root, id) => {
          if (!id) {
            id = root.ganttItemUuid;
            root.root_id = root.ganttItemUuid;
          }
          if (root.children && root.children.length > 0) {
            root.children.forEach((child) => {
              child.root_id = id;
              dep_root_id(child, id);
            });
          }
        };
        this.beforeTableData.forEach((item) => {
          item.children = [];
          item.show = true;
          if (item.startTime && item.dueTime) {
            item.allTime = this.calcDiffBetweenDays(
              item.startTime,
              item.dueTime
            );
          } else {
            item.allTime = 0;
          }
          tempObj[item.ganttItemUuid] = item.children;
          nodeKeyObj[item.nodeKey] = item;
          nodeArr.push(item.nodeKey);
        });
        let all_node_info = await this.get_all_node_filetype(nodeArr);
        //nodeInfo一一对应
        for (let i = 0; i < all_node_info.length; i++) {
          let node_key_table = all_node_info[i].key;
          let assignee = all_node_info[i].assignee;
          if (nodeKeyObj[node_key_table]) {
            let assignee_name = this.getUser(assignee);
            nodeKeyObj[node_key_table].nodeInfo = all_node_info[i];
            nodeKeyObj[node_key_table].person = assignee_name
              ? assignee_name.nickname
              : "-";
            nodeKeyObj[node_key_table].statusCategory =
              all_node_info[i].statusCategory;
            nodeKeyObj[node_key_table].status = all_node_info[i].status;
            nodeKeyObj[node_key_table].name = all_node_info[i].topic;
            nodeKeyObj[node_key_table].statusName = this.getStatusName(
              all_node_info[i].status
            );
          }
        }
        //children打通
        this.beforeTableData.forEach((item) => {
          if (
            item.parentItemUuid !== "" &&
            tempObj[item.parentItemUuid] !== undefined
          ) {
            tempObj[item.parentItemUuid].push(item);
          } else if (item.parentItemUuid === "") {
            tempArr.push(item);
          }
        });
        let keys = Object.keys(tempObj);
        keys.forEach((key) => {
          tempObj[key].sort((a, b) => {
            return a.index - b.index;
          });
        });
        //root_id统一
        tempArr.forEach((item) => {
          dep_root_id(item);
        });
        this.tableData = tempArr;
        this.tableData2 = JSON.parse(JSON.stringify(this.tableData));
      } else {
        this.tableData = [];
        this.tableData2 = [];
      }
    },
    //切换甘特图
    async ganttChange(item) {
      this.closeAllchart();
      this.ganttChartsList.forEach((item1) => {
        if (item1.ganttChartId == item) {
          item = item1;
        }
      });
      this.cur_gant_id = item.ganttChartId;
      await this.closeWebsocket();
      this.selectedGanttChart = item;
      this.is_show_chart = false;
      this.statusFilter = [];
      this.personFilter = [];
      this.checkAllPerson = false;
      this.checkAllStatus = false;
      this.isIndeterminatePerson = false;
      this.isIndeterminateStatus = false;
      await this.initWebsocket();
      this.initGanttNodes();
    },
    //按月按年按季度回调
    handleCommandCell(item) {
      this.top_options.show_zoom = item.title;
      this.statusCell = item.tag;
      if (this.statusCell === "byMonth") {
        this.startLen = 40;
        this.globalScale_v = 1;
      } else if (this.statusCell === "byQuarter") {
        this.startLen = 200;
        this.globalScale_v = 0.4;
      } else if (this.statusCell === "byYear") {
        this.startLen = 400;
        this.globalScale_v = 0.2;
      }
      this.renderGantt();
    },
    //按负责人按群组回调
    handleCommandLegend(item) {
      this.top_options.show_legend = item.legendName;
      this.statusLegend = item.tag;
      this.paletteStatusObj = {};
      this.renderGantt();
    },
    show_chart() {
      this.is_show_chart = !this.is_show_chart;
      this.isShowDetailInfo = false;
      if (this.is_show_chart) {
        this.table1Check = [this.cur_gant_id];
        this.getBatchAnalyzeGanttStatus();
        this.$nextTick(() => {
          this.getChartData();
        });
      } else {
        this.renderGantt();
        this.closeAllchart();
      }
    },
    getBatchAnalyzeGanttStatus(current) {
      let defaultIndex = 0;
      if (!current) {
        this.ganttChartsList.forEach((gantt, index) => {
          if (gantt.ganttChartId === this.cur_gant_id) {
            defaultIndex = index;
          }
        });
      }
      this.table1pages.total = this.ganttChartsList.length;
      this.table1pages.current = current || Math.floor(defaultIndex / 6) + 1;
      const ganttIds = [];
      this.allGanttCurrentData = [];
      for (
        let i = (this.table1pages.current - 1) * 6;
        i < this.table1pages.current * 6;
        i++
      ) {
        if (this.ganttChartsList[i]) {
          ganttIds.push(this.ganttChartsList[i].ganttChartId);
        }
      }
      const params = {
        projectId: this.get_pid(),
        data: {
          ganttChartUuids: ganttIds,
        },
      };
      getBatchAnalyzeGanttStatus(params).then((res) => {
        this.currentMaxNum = 0;
        this.allGanttCurrentData = res;
        res.forEach((item) => {
          let total = 0;
          item.ganttStatusDistributionList &&
            item.ganttStatusDistributionList.forEach((single) => {
              total += single.count;
            });
          if (total > this.currentMaxNum) {
            this.currentMaxNum = total;
          }
        });
        this.$nextTick(() => {
          this.lockSelect = true;
          if (!current) {
            res.forEach((row) => {
              if (row.ganttChartUuid === this.cur_gant_id) {
                this.$refs.allGanttTable.toggleRowSelection(row, true);
                this.table1Check = [this.cur_gant_id];
              }
            });
          } else {
            res.forEach((row) => {
              if (this.table1Check.indexOf(row.ganttChartUuid) !== -1) {
                this.$refs.allGanttTable.toggleRowSelection(row, true);
              }
            });
          }
          this.lockSelect = false;
        });
      });
    },
    getChartData() {
      let status_list = [];
      let responsible_list = [];
      let responsible_status = [];
      let responsible_time = [];
      const params = {
        projectId: this.get_pid(),
        data: this.table1Check,
      };
      getPlannedWorkingHoursStatus(params).then((res) => {
        if (res) {
          for (const key in this.$store.getters.status) {
            res.forEach((item) => {
              if (item.status == key) {
                status_list.push({
                  value: item.count,
                  name: this.$store.getters.status[key].name,
                });
              }
            });
          }
          this.status_chart(status_list);
        }
      });

      getPlannedWorkingHoursAssignee(params).then((res) => {
        if (res) {
          for (const key in this.$store.getters.user_list) {
            res.forEach((item) => {
              if (item.assignee == key) {
                responsible_list.push({
                  value: item.count,
                  name: this.$store.getters.user_list[key].nickname,
                });
              }
            });
          }
          res.map((item) => {
            if (item.assignee == "") {
              responsible_list.push({
                value: item.count,
                name: "null",
              });
            }
          });

          this.responsible_chart(responsible_list);
        }
      });

      getPlannedWorkingHoursTable(params).then((res) => {
        if (res) {
          this.tableshow = true;
          res.map((item) => {
            responsible_status.push(item.status);
          });
          this.tableStatusAll = [...new Set(responsible_status)];
          this.setTableShowList(res);
        } else {
          this.tableshow = false;
        }
      });

      getPlannedWorkingHoursTime(params).then((res) => {
        if (res) {
          for (const key in this.$store.getters.user_list) {
            res.forEach((item) => {
              if (item.assignee == key) {
                responsible_time.push({
                  planManHour: item.totalPlanManHour,
                  manHour: item.totalManHour,
                  name: this.$store.getters.user_list[key].nickname,
                });
              }
            });
          }
          res.map((item) => {
            if (item.assignee == "") {
              responsible_time.push({
                planManHour: item.totalPlanManHour,
                manHour: item.totalManHour,
                name: "null",
              });
            }
          });

          this.responsible_column_chart(responsible_time);
        }
      });
    },
    setTableShowList(list) {
      let status_real = [];
      for (const key in this.$store.getters.status) {
        this.tableStatusAll.forEach((item) => {
          if (item == key) {
            status_real.push(this.$store.getters.status[key].name);
          }
        });
        list.forEach((item) => {
          if (item.status == key) {
            item.status = this.$store.getters.status[key].name;
          }
        });
      }
      for (const key in this.$store.getters.user_list) {
        list.forEach((item) => {
          if (item.assignee == key) {
            item.assignee = this.$store.getters.user_list[key].nickname;
          }
        });
      }
      list.forEach((flag) => {
        if (flag.assignee == "") flag.assignee = "null";
      });

      this.tableStatusAll = status_real;
      this.tableStatusAll.push(this.$t("gantt.chart.title.title8"));
      this.setShowChartList(list);
    },
    calculateTotal(row) {
      let add = 0;
      this.tableStatusAll.map((item) => {
        add += row[item];
      });
      row[this.$t("gantt.chart.title.title8")] = add;
    },
    sumObjectsByKey(arr) {
      return arr.reduce((acc, cur) => {
        for (let prop in cur) {
          if (prop !== "respeople") {
            acc[prop] = (acc[prop] || 0) + cur[prop];
          }
        }
        return acc;
      }, {});
    },
    setShowChartList(list) {
      this.tablechartData = [];
      list.map((item) => {
        this.tablechartData.unshift({
          respeople: item.assignee,
          [item.status]: item.count,
        });
      });
      let mergedArray = this.tablechartData.reduce(function (
        accumulator,
        currentItem
      ) {
        let existingItem = accumulator.find(function (item) {
          return item.respeople === currentItem.respeople;
        });
        if (existingItem) {
          Object.assign(existingItem, currentItem);
        } else {
          accumulator.push(currentItem);
        }
        return accumulator;
      },
      []);
      this.tablechartData = mergedArray;
      let raw_data = [];
      let raw_null = {};
      this.tablechartData.forEach((item) => {
        if (item.respeople != this.$t("gantt.chart.title.title8")) {
          this.tableStatusAll.forEach((flag) => {
            if (flag in item) {
            } else {
              item[flag] = 0;
            }
          });
        }
      });
      this.tablechartData.forEach((ele) => {
        if (ele.respeople == "null") {
          raw_null = ele;
        } else {
          raw_data.push(ele);
        }
      });
      raw_data.push(raw_null);
      let ori_data = this.sumObjectsByKey(raw_data);
      ori_data.respeople = this.$t("gantt.chart.title.title8");
      raw_data.push(ori_data);
      this.tablechartData = raw_data;
      this.tablechartData.map((item) => {
        this.calculateTotal(item);
      });
    },
    deleteSelectedGanttChart() {
      deleteGanttChart(
        this.get_pid(),
        this.selectedGanttChart.ganttChartId
      ).then((res) => {
        //重新渲染
        this.initGanttChartsList();
        this.dialogDeleteGanttVisible = false;
        this.$message.success(this.$t("gantt.deletedSuccessfully"));
      });
    },
    initWebsocket() {
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer?accountId=${this.userAccountId}`
      );
      this.stompClient = Stomp.over(socket);
      const self = this;
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "GANTT_CHART_MODIFY",
        }, //传递token
        (frame) => {
          // 测试topic
          this.stompClient.subscribe(
            `/topic/GANTT_CHART_MODIFY/${this.get_pid()}/${
              this.selectedGanttChart.ganttChartId
            }`,
            (res) => {
              if (res.body !== this.userAccountId) {
                this.draggable = false;
                this.allowHandle = false;
                if (this.notify) {
                  this.notify.close();
                }
                this.notify = this.$notify({
                  title: this.$t("addMindMap.title2"),
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                  message: `<span>${this.$t(
                    "gantt.hasChanged"
                  )}<span style="color: blue; cursor: pointer">${this.$t(
                    "addMindMap.btn3"
                  )}</span></span>`,
                  duration: 0,
                  onClick: function () {
                    if (res !== undefined) {
                      // self.$router.go(0);
                      self.initGanttChartsList(
                        self.selectedGanttChart.ganttChartId
                      );
                      self.notify.close();
                      self.draggable = true;
                      self.allowHandle = true;
                    }
                  },
                });
              } else {
                this.initGanttChartsList(self.selectedGanttChart.ganttChartId);
              }
            }
          );
        },
        (err) => {}
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {}
      }
    },

    //2.el-tree部分---------------------------------------------------------

    //默认展示列
    checkListInit() {
      if (localStorage.getItem("checkList_store")) {
        this.checkList = JSON.parse(localStorage.getItem("checkList_store"));
      } else {
        this.checkList = {
          person: true,
          status: true,
          all_time: true,
          key: true,
          work_hours: true,
        };
      }
    },
    //获得状态名
    getStatusName(statusId) {
      if (this.status[statusId]) {
        return this.status[statusId].name;
      }
      return "";
    },
    //获得负责人名称
    getUser(userId) {
      return this.matchUserInfo(userId);
    },
    //修改负责人失焦处理
    away(data) {
      if (this.tempNodeName === "" || this.tempNodeName === data.name) {
        this.$set(data, "is_adit_name", undefined);
        return;
      }
      let node_info = data.nodeInfo;
      data.name = this.tempNodeName;
      node_info.topic = this.tempNodeName;
      const params = {
        projectId: this.get_pid(),
        nodeKey: node_info.key,
        data: {
          isCustom: false,
          fieldId: "topic",
          value: node_info.topic,
        },
      };
      only_set_property(params).then(() => {
        this.tempNodeName = "";
        this.$set(data, "is_adit_name", undefined);
      });
    },
    editPerson(data) {
      this.$set(data, "is_edit_person", true);
      this.$nextTick(() => {
        this.$refs[data.nodeKey + "_person"].focus();
      });
    },
    assignee_blur(data) {
      let blur_again = () => {
        removeEventListener("click", blur_again);
        this.$nextTick(() => {
          this.$set(data, "is_edit_person", undefined);
          this.tableData2 = JSON.parse(JSON.stringify(this.tableData));
        });
      };
      document.addEventListener("click", blur_again);
    },
    assignee_change(data) {
      if (this.assignee === "") {
        return;
      }
      multiLayers_change_assignee({
        projectId: this.get_pid(),
        nodeKeys: [data.nodeKey],
        data: {
          accountId: this.assignee,
        },
      }).then((res) => {
        data.person = this.getUser(this.assignee).nickname;
        this.tableData.forEach((item) => {
          if (item.nodeKey === data.nodeKey) {
            item.person = data.person;
            item.nodeInfo.assignee = this.assignee;
          }
        });
        this.beforeTableData.forEach((item) => {
          if (item.nodeKey === data.nodeKey) {
            item.person = data.person;
            item.nodeInfo.assignee = this.assignee;
          }
        });
        this.assignee = "";
        this.renderGantt();
        this.$nextTick(() => {
          this.$set(data, "is_edit_person", undefined);
          this.tableData2 = JSON.parse(JSON.stringify(this.tableData));
        });
      });
    },
    nodeLink(data) {
      get_file(this.get_pid(), data.nodeInfo.fileId).then((result) => {
        let fileKey = result.key;

        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${fileKey}`,
          query: {
            node_id: data.nodeInfo.id,
          },
        });
        window.open(href, "_blank");
      });
    },
    nodeKeyLink(data) {
      clearTimeout(this.tempTimer);
      this.tempTimer = setTimeout(() => {
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/nodes/key/${data.nodeKey}`,
        });
        window.open(href, "_blank");
      }, 300);
    },
    doubleClick(data) {
      clearTimeout(this.tempTimer);
      if (this.jobAuthorities.indexOf("NODE_EDIT") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        return;
      }
      this.$set(data, "is_adit_name", true);
      this.tempNodeName = data.name || "";
      this.$nextTick(() => {
        this.$refs[data.nodeKey + "_input"].focus();
      });
    },
    //el-tree的监听函数
    handleDragStart(node, ev) {},
    handleDragEnter(draggingNode, dropNode, ev) {},
    handleDragLeave(draggingNode, dropNode, ev) {},
    handleDragOver(draggingNode, dropNode, ev) {},
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // 父元素统一子节点的root_id
      const dps_handleEnd = (node) => {
        if (node.childNodes && node.childNodes.length) {
          let root_id = node.data.root_id;
          node.childNodes.forEach((child) => {
            child.data.root_id = root_id;
            dps_handleEnd(child);
          });
        }
      };

      //拖入嵌套逻辑
      if (dropType === "inner") {
        // dropNode.data.level = dropNode.level;level已经不用了，直接用node的level就行
        dps_handleEnd(dropNode);
      } else {
        if (!draggingNode.parent) {
          draggingNode.data.root_id = draggingNode.data.id;
        }
        dps_handleEnd(draggingNode);
      }
      let params = this.findObjectByIdReturnParams(
        draggingNode.data.ganttItemUuid,
        this.tableData,
        ""
      );
      if (params.toGanttItemUuid === "") {
        params.toGanttItemUuid = "root";
      }
      changePosition(this.get_pid(), params);

      this.renderGantt();
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {},
    //节点被展开触发的事件
    handleExpand(data, node, component) {
      const dps_handleExpand = (node) => {
        if (node.childNodes && node.childNodes.length && node.expanded) {
          node.childNodes.forEach((child) => {
            child.data.show = true;
            dps_handleExpand(child);
          });
        }
      };
      dps_handleExpand(node);
      //这个生命周期非常诡异，他的结算动画是在这个函数执行完之后一段时间才运行，这就导致了我们也得等等
      setTimeout(() => {
        this.renderGantt();
      }, 400);
    },
    //节点被关闭触发的事件
    handleCollapse(data, node, component) {
      const dps_handleCollapse = (node) => {
        if (node.childNodes && node.childNodes.length) {
          node.childNodes.forEach((child) => {
            child.data.show = false;
            dps_handleCollapse(child);
          });
        }
      };
      dps_handleCollapse(node);
      setTimeout(() => {
        this.renderGantt();
      }, 400);
    },
    allowDrop(draggingNode, dropNode, type) {
      return true;
      // if (dropNode.data.label === "二级 3-1") {
      //   return type !== "inner";
      // } else {
      //   return true;
      // }
    },
    allowDrag(draggingNode) {
      return true;
    },

    //3.graph部分---------------------------------------------------------

    //初始化画布宽度
    async initTableWidth() {
      await getTimeLength(
        this.get_pid(),
        this.selectedGanttChart.ganttChartId
      ).then((res) => {
        this.globalStartTime = res.startTime;
        this.globalDueTime = res.dueTime;
        if (this.globalStartTime && this.globalDueTime) {
          let start = moment(this.globalStartTime).year();
          let end = moment(this.globalDueTime).year();
          let betweenYears = 1 + parseInt(end) - parseInt(start);
          this.tableWidth = 12 * betweenYears * 31 * 20 + this.startLen;
        } else {
          this.tableWidth = 12 * 31 * 20 + this.startLen;
        }
        this.tableWidth =this.tableWidth *this.globalScale_v
      });
    },
    //修改节点时间
    changeDataByNode(node) {
      let id = node.id || null;
      if (id) {

        let position = node.getPosition();
        let size = node.getSize();
        let table_node = this.findObjectById(id, this.tableData);

        let start_time = parseInt(
          (position.x - this.startLen * this.globalScale_v) /
            20 /
            this.globalScale_v
        );

        let end_time = parseInt(size.width / this.globalScale_v / 20);

        let date = moment(moment(this.globalStartTime).year() + "-01-01");
        date.add(start_time, "days");
        table_node.startTime = date.format();
        date.add(end_time, "days");
        table_node.dueTime = date.format();
        table_node.allTime = end_time;
        if (this.throttleTimer[table_node.ganttItemUuid]) {
          clearTimeout(this.throttleTimer[table_node.ganttItemUuid]);
        }
        this.throttleTimer[table_node.ganttItemUuid] = setTimeout(() => {
          this.timeChange(table_node, true);
        }, 2000);
      } else {
        this.$message.warning(this.$t("gantt.missingId"));
      }
    },
    close_important_route() {
      this.is_show_important_route = false;
      for (let edge of this.graph.getEdges()) {
        if (edge.store.data.source.cell && edge.store.data.source.cell) {
          edge.attr("line/stroke", "#a6a6a6");
        }
      }
    },
    timeChange(data, notReRender) {
      this.close_important_route();

      let startTime = data.startTime;
      let dueTime = data.dueTime;
      if (startTime && dueTime && startTime > dueTime) {
        let timer = data.startTime.getTime();
        data.dueTime = new Date(timer);
      }
      if (startTime && dueTime) {
        let formatStart = moment(startTime).format();
        let formatEnd = moment(dueTime).format();
        let param = {
          projectId: this.get_pid(),
          ganttItemUuid: data.ganttItemUuid,
          data: {
            startTime: formatStart,
            dueTime: formatEnd,
          },
        };

        changeAllTime(param)
          .then((res) => {
            this.initGanttNodes(notReRender);
          })
          .catch((err) => {});
      } else if (startTime === null && dueTime === null) {
        let param = {
          projectId: this.get_pid(),
          ganttItemUuid: data.ganttItemUuid,
        };
        ClearGanttOffsetTime(param).then((res) => {
          this.initGanttNodes(notReRender);
        });
      }
    },
    //注册全局边or节点
    registerEdgeAndNode() {
      //注册自定义边
      // 注册自定义边类型
      try {
        Graph.registerEdge("custom-edge", {
          inherit: "edge",
          // 属性样式
          attrs: {
            line: {
              stroke: "#a6a6a6",
            },
          },
        });
      } catch (e) {}
    },
    //批量删除
    initDeleteAddEventListener() {
      let that = this;
      document.onkeydown = function (event) {
        if (event.key === "Delete" && that.beforeDeleteList.length) {
          let params = {
            projectId: that.get_pid(),
            ganttItemIdList: that.beforeDeleteList,
            ganttChartId: that.selectedGanttChart.ganttChartId,
          };
          deleteNodeList(params).then((res) => {
            that.initGanttNodes();
          });
        }
      };
    },
    beforeInsertRootNode() {
      if (!this.allowHandle) {
        this.$message.warning(this.$t("gantt.structureModified"));
        return;
      }
      this.parentId = "";
      this.dialogFormVisible = true;
    },
    //插入根节点
    insertRootNodeToChart(nodeKeyList) {
      if (this.selectedGanttChart.ganttChartId && nodeKeyList.length) {
        const dataList = [];
        nodeKeyList.forEach((nodeKey, index) => {
          let data = {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            parentItemUuid: "",
            nodeKey: nodeKey,
            layer: 1,
            index: this.tableData.length + 1 + index,
          };
          dataList.push(data);
        });
        let params = {};
        params.ganttItemList = dataList;
        params.projectId = this.get_pid();
        postNodeList(params)
          .then((res) => {
            this.initGanttNodes();
            this.dialogFormVisible = false;
          })
          .catch((err) => {
            this.dialogFormVisible = false;
          });
      } else {
        this.parentId = "";
        this.$message.warning(this.$t("gantt.NoCurrentlySelected"));
      }
    },
    //插入子节点前的准备
    beforeInsertChildNode(data) {
      if (!this.allowHandle) {
        this.$message.warning(this.$t("gantt.structureModified"));
        return;
      }
      this.childIndex = data.children.length + 1;
      this.childLayer = parseInt(data.layer) + 1;
      this.parentNode = data.nodeInfo;
      this.parentId = data.ganttItemUuid || "";
      this.dialogFormVisible = true;
    },
    //插入子节点
    insertChildNodeToChart(nodeKeyList) {
      if (this.selectedGanttChart.ganttChartId) {
        const dataList = [];
        nodeKeyList.forEach((nodeKey, index) => {
          let data = {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            parentItemUuid: this.parentId,
            nodeKey: nodeKey,
            layer: this.childLayer,
            index: this.childIndex + index,
          };
          dataList.push(data);
        });
        let params = {};
        params.ganttItemList = dataList;
        params.projectId = this.get_pid();
        postNodeList(params)
          .then((res) => {
            this.parentId = "";
            this.initGanttNodes();
            this.dialogFormVisible = false;
          })
          .catch((err) => {
            this.parentId = "";
            this.dialogFormVisible = false;
          });
      } else {
        this.parentId = "";
        this.$message.warning(this.$t("gantt.NoCurrentlySelected"));
      }
    },
    //监听朱元璋器初始化
    listenerInit(graph) {
      let that = this;
      //拉伸监听
      graph.on("node:change:size", ({ node, options }) => {
        const children = node.getChildren();
        if (children && children.length) {
          let targetLists = node.prop("target_depend") || [];
          this.handleTargetLists(node, targetLists);
          this.changeDataByNode(node);
        }
      });
      //位置监听
      graph.on("node:change:position", ({ node, options }) => {

        if (options.skipParentHandler) {
          return;
        }
        const children = node.getChildren();

        if (children && children.length) {
          node.prop("originPosition", node.getPosition());
          let targetLists = node.prop("target_depend") || [];
          let sourceLists = node.prop("source_depend") || [];
          this.handleTargetLists(node, targetLists);
          this.handleSourceLists(node, sourceLists);
          this.changeDataByNode(node);
        } else {
          if (node.store.data.size.width > 3) {
            node.prop("originPosition", node.getPosition());
            let targetLists = node.prop("target_depend") || [];
            let sourceLists = node.prop("source_depend") || [];
            this.handleTargetLists(node, targetLists);
            this.handleSourceLists(node, sourceLists);
            this.changeDataByNode(node);
          }
        }

        const parent = node.getParent();
        if (parent && parent.isNode()) {
          let originSize = parent.prop("originSize");
          if (originSize == null) {
            parent.prop("originSize", parent.getSize());
          }
          originSize = parent.prop("originSize");
          let originPosition = parent.prop("originPosition");
          if (originPosition == null) {
            parent.prop("originPosition", parent.getPosition());
          }
          originPosition = parent.prop("originPosition");
          //这里是父节点的x,y位置
          let x = originPosition.x;
          let y = originPosition.y;
          let cornerX = originPosition.x + originSize.width;
          let cornerY = originPosition.y + originSize.height;
          let hasChange = false;
          const children = parent.getChildren();
          if (children) {
            children.forEach((child) => {
              const bbox = child.getBBox();
              const corner = bbox.getCorner();
              if (bbox.x < x) {
                x = bbox.x - 20;
                hasChange = true;
              }
              if (corner.x > cornerX) {
                cornerX = corner.x;
                hasChange = true;
              }
              if (corner.x < cornerX - 20) {
                cornerX = corner.x;
                hasChange = true;
              }
            });
          }
          if (hasChange) {
            parent.size({ width: cornerX - x + 20, height: originSize.height });
            //size会更新索引，索引需要保持一致
            parent.prop(
              {
                position: { x, y },
              },
              //防止死循环
              { skipParentHandler: true }
            );
          }
        }
      });
      //连接监听
      graph.on("edge:connected", ({ isNew, edge }) => {
        this.handleConnect(edge, true);
      });
      //选中监听
      graph.on(
        "selection:changed",
        ({
          added, // 新增被选中的节点/边
          removed, // 被取消选中的节点/边
          selected, // 被选中的节点/边
          options,
        }) => {
          let beforeList = [];
          selected.forEach((item) => {
            beforeList.push(item.id);
          });

          that.beforeDeleteList = beforeList;
        }
      );
      //删除连接线监听
      graph.on("edge:mouseenter", function (event) {
        let edge = event.edge;
        // edge.appendOuterHTML('<button class="delete-button">Delete</button>');
        if (edge.shape === "custom-edge" && edge.labels.length === 0) {
          const label = edge.appendLabel({
            position: 0.5,
            attrs: {
              text: {
                text: "X",
                fill: "red",
              },
            },
          });
        }
      });

      graph.on("edge:mouseleave", function (event) {
        let edge = event.edge;
        if (edge.shape === "custom-edge") {
          if (edge.labels && edge.labels.length) {
            edge.removeLabelAt(0);
          }
        }
      });

      graph.on("edge:click", (event) => {
        let edge = event.edge;
        if (edge.shape === "custom-edge" && edge.labels.length) {
          let sourceNode = edge.getSourceNode();
          let targetNode = edge.getTargetNode();
          let sourceNodePort = edge.getSourcePortId();

          let targetList = sourceNode.prop("target_depend") || [];
          let sourceList = targetNode.prop("source_depend") || [];
          targetList =
            targetList.filter((obj) => obj.id !== targetNode.id) || [];
          sourceList =
            sourceList.filter((obj) => obj.id !== sourceNode.id) || [];
          sourceNode.prop("target_depend", targetList);
          targetNode.prop("source_depend", sourceList);
          this.handleDepend("delete", {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            firstGanttItemUuid: sourceNode.id,
            secondGanttItemUuid: targetNode.id,
            ganttRelationType: sourceNodePort === "start" ? 1 : 2,
          });
          edge.remove();
        }
      });
    },
    //渲染连接线
    renderConnect() {
      this.close_important_route();
      getGanttNodeAndDepend(
        this.get_pid(),
        this.is_show_chart
          ? this.table1Check
          : [this.selectedGanttChart.ganttChartId]
      ).then((res) => {
        this.relationList = res.relationList || [];

        if (this.relationList && this.relationList.length) {
          this.relationList.forEach((item) => {
            let temp;
            if (item.relationType === 1) {
              temp = ["start", "start"];
            } else {
              temp = ["isEnd", "start"];
            }
            if (
              this.graph.getCellById(item.firstItemUuid) &&
              this.graph.getCellById(item.secondItemUuid)
            ) {
              let edge = this.graph.addEdge({
                shape: "custom-edge",
                source: { cell: item.firstItemUuid, port: temp[0] },
                target: { cell: item.secondItemUuid, port: temp[1] },
              });
              let result = this.handleConnect(edge, false);
              if (!result) {
                this.handleDepend("delete", {
                  ganttChartUuid: this.selectedGanttChart.ganttChartId,
                  firstGanttItemUuid: item.firstItemUuid,
                  secondGanttItemUuid: item.secondItemUuid,
                  ganttRelationType: item.relationType,
                });
              }
            }
          });
        }
      });
    },
    handleDepend(type, params) {
      if (type == "add") {
        addGanttNodeAndDepend(this.get_pid(), params);
      } else {
        deleteGanttNodeAndDepend(this.get_pid(), params);
      }
    },
    handleConnect(edge, isConnect) {
      const sourceNode = edge.getSourceNode();
      const targetNode = edge.getTargetNode();
      const sourcePort = edge.getSourcePortId().slice(0, 5);
      const targetPort = edge.getTargetPortId().slice(0, 5);
      let source = sourceNode.getPosition();
      let target = targetNode.getPosition();
      if (
        targetNode.prop("source_depend") &&
        targetNode.prop("source_depend").length
      ) {
        this.$message.warning(this.$t("gantt.forbiddenSameConnectionStake"));
        edge.remove();
        return false;
      } else if (sourcePort === "isEnd" && targetPort === "start") {
        //尾部连接头部
        let source_len = sourceNode.getSize();
        let target_len = targetNode.getSize();
        let children = targetNode.getChildren();
        let cornerX = source.x + source_len.width;
        let y = target.y;
        // if (cornerX > target.x) {
        //   targetNode.position(cornerX, y);
        //   if (children && children.length) {
        //     let drag_child = children[0];
        //     drag_child.position(
        //       cornerX + target_len.width - this.gridLen,
        //       y + this.ganttDragTop
        //     );
        //   }
        // }
        source = sourceNode.getPosition();
        target = targetNode.getPosition();
        let Xlength = target.x - (source.x + source_len.width);

        //给源节点存目标节点的信息
        let target_depend = {
          status: "end_start",
          id: targetNode.id,
          other: sourceNode.id,
          Xlength,
        };
        let target_dependNode = sourceNode.prop("target_depend");
        if (target_dependNode == null) {
          sourceNode.prop("target_depend", []);
          target_dependNode = sourceNode.prop("target_depend");
        }
        target_dependNode.push(target_depend);
        //给目标节点存源节点的信息
        let source_depend = {
          status: "end_start",
          id: sourceNode.id,
          other: targetNode.id,
          Xlength,
        };
        let source_dependNode = targetNode.prop("source_depend");
        if (source_dependNode == null) {
          targetNode.prop("source_depend", []);
          source_dependNode = targetNode.prop("source_depend");
        }
        source_dependNode.push(source_depend);
        if (isConnect) {
          this.handleDepend("add", {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            firstGanttItemUuid: sourceNode.id,
            secondGanttItemUuid: targetNode.id,
            ganttRelationType: 2,
          });
        }
        return true;
      } else if (sourcePort === "start" && targetPort === "start") {
        //头部连接头部
        let source_len = sourceNode.getSize();
        let target_len = targetNode.getSize();
        let children = targetNode.getChildren();
        let cornerX = source.x + source_len.width;
        let y = target.y;
        // if (source.x > target.x) {
        //   targetNode.position(source.x, y);
        //   if (children && children.length) {
        //     let drag_child = children[0];
        //     drag_child.position(
        //       source.x + target_len.width - this.gridLen,
        //       y + this.ganttDragTop
        //     );
        //   }
        // }
        source = sourceNode.getPosition();
        target = targetNode.getPosition();
        let Xlength = target.x - source.x;

        //给源节点存目标节点的信息
        let target_depend = {
          status: "start_start",
          id: targetNode.id,
          other: sourceNode.id,
          Xlength,
        };
        let target_dependNode = sourceNode.prop("target_depend");
        if (target_dependNode == null) {
          sourceNode.prop("target_depend", []);
          target_dependNode = sourceNode.prop("target_depend");
        }
        target_dependNode.push(target_depend);
        //给目标节点存源节点的信息
        let source_depend = {
          status: "start_start",
          id: sourceNode.id,
          other: targetNode.id,
          Xlength,
        };
        let source_dependNode = targetNode.prop("source_depend");
        if (source_dependNode == null) {
          targetNode.prop("source_depend", []);
          source_dependNode = targetNode.prop("source_depend");
        }
        source_dependNode.push(source_depend);

        if (isConnect) {
          this.handleDepend("add", {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            firstGanttItemUuid: sourceNode.id,
            secondGanttItemUuid: targetNode.id,
            ganttRelationType: 1,
          });
        }
        return true;
      } else if (sourcePort === "start" && targetPort === "isEnd") {
        //头部连接尾部
        let source_len = sourceNode.getSize();

        let Xlength = target.x - source.x;

        //给源节点存目标节点的信息
        let target_depend = {
          status: "start_end",
          id: targetNode.id,
          other: sourceNode.id,
          Xlength,
        };
        let target_dependNode = sourceNode.prop("target_depend");
        if (target_dependNode == null) {
          sourceNode.prop("target_depend", []);
          target_dependNode = sourceNode.prop("target_depend");
        }
        target_dependNode.push(target_depend);
        //给目标节点存源节点的信息
        let source_depend = {
          status: "start_end",
          id: sourceNode.id,
          other: targetNode.id,
          Xlength,
        };
        let source_dependNode = targetNode.prop("source_depend");
        if (source_dependNode == null) {
          targetNode.prop("source_depend", []);
          source_dependNode = targetNode.prop("source_depend");
        }
        source_dependNode.push(source_depend);

        if (isConnect) {
          this.handleDepend("add", {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            firstGanttItemUuid: sourceNode.id,
            secondGanttItemUuid: targetNode.id,
            ganttRelationType: 1,
          });
        }
        return true;
      }
    },
    handleTargetLists(node, targetLists) {
      if (targetLists.length === 0) {
        return;
      }
      let position = node.getPosition();
      let size = node.getSize();
      targetLists.forEach((target) => {
        let targetNode = this.graph.getCellById(target.id);
        if (targetNode) {
          let t_position = targetNode.getPosition();
          if (target.status === "end_start") {
            let x_node = target.Xlength + position.x + size.width;
            let translate_x = x_node - t_position.x;
            targetNode.translate(translate_x, 0);
          } else {
            let x_node = target.Xlength + position.x;
            let translate_x = x_node - t_position.x;
            targetNode.translate(translate_x, 0);
          }
        }
      });
    },
    handleSourceLists(node, sourceLists) {
      if (sourceLists.length === 0) {
        return;
      }
      let position = node.getPosition();
      sourceLists.forEach((source) => {
        let item = this.graph.getCellById(source.id);
        let temp;
        let targetList = item.prop("target_depend");
        for (let i = 0; i < targetList.length; i++) {
          if (targetList[i].id == source.other) {
            temp = targetList[i];
            break;
          }
        }
        if (temp === undefined) {
          return;
        }
        if (source.status === "start_start") {
          temp.Xlength = source.Xlength = position.x - item.getPosition().x;
        } else {
          temp.Xlength = source.Xlength =
            position.x - item.getPosition().x - item.getSize().width;
        }
      });
    },
    //总渲染
    async renderGantt() {
      if (this.graph) {
        this.graph.dispose();
      }
      await this.initTableWidth();
      this.$refs.container.style.transform = `scale(${this.globalScale},1.0)`;
      this.cur_palette = 0; //调色盘指针
      this.paletteStatusObj = {};
      this.isShowDetailInfo = false;
      this.$nextTick(() => {
        //获取表格各行高度
        let header = document.querySelector("#header_tree_node").offsetHeight;
        let el_tree_oneItem = document.querySelector(".el-tree-node__content")
          ? document.querySelector(".el-tree-node__content").offsetHeight
          : 0;
        let el_tree_height = document.querySelector(".el-tree").offsetHeight;
        let ganttitem_top = parseInt(
          (el_tree_oneItem - this.ganttItemHeight) / 2
        );
        ganttitem_top = ganttitem_top > 0 ? ganttitem_top : 0;
        //获取表格总高度
        const tableHeight = el_tree_height;
        let tableWidth = this.tableWidth;
        this.rowHeightList = [0];
        let temp = document.getElementsByClassName("el-tree-node__content");
        for (let i = 0; i < temp.length - 1; i++) {
          const item = temp[i];
          if (item.offsetHeight !== 0) {
            this.rowHeightList.push(item.offsetHeight);
          }
        }
        //创建画布
        //&h
        this.graph = new Graph({
          container: document.getElementById("container"),
          height: tableHeight,
          width: tableWidth,
          autoResize: true, // 自动缩放大小
          background: {
            color: "#fff",
          },
          grid: {
            size: this.gridLen,
            visible: false,
            type: "doubleMesh",
            args: [
              {
                color: "#eee", // 主网格线颜色
                thickness: 1, // 主网格线宽度
              },
              {
                color: "#ddd", // 次网格线颜色
                thickness: 1, // 次网格线宽度
                factor: 7, // 主次网格线间隔
              },
            ],
          },
          selecting: {
            enabled: true,
            rubberband: true,
            filter: function (cellView) {
              if (
                cellView.id.split("@")[0] == "line_node" ||
                cellView.store.data.shape == "rect"
              ) {
                return false;
              } else {
                return true;
              }
            },
            showNodeSelectionBox: true,
          },
          interacting: function (cellView) {
            if (cellView.cell.id.split("@")[0] == "line_node") {
              return { nodeMovable: false };
            }
            return {
              nodeMovable: true, //节点是否可以被移动
              edgeMovable: false, //边是否可以被移动
              edgeLabelMovable: false, //边的标签是否可以被移动
            };
          },
          translating: {
            restrict: (params) => {
              let box = params.getBBox();
              let cell = params.cell;
              if (cell.id === "header_node") {
                return {
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
                };
              }
              if (cell) {
                let sourceLists = cell.prop("source_depend");
                if (sourceLists && sourceLists.length) {
                  let maxX = 0;
                  sourceLists.forEach((source) => {
                    let sourceNode = this.graph.getCellById(source.id);
                    if (sourceNode) {
                      let s_position = sourceNode.getPosition();
                      let s_size = sourceNode.getSize();
                      if (source.status === "end_start") {
                        let end = s_position.x + s_size.width;
                        maxX = maxX > end ? maxX : end;
                      } else {
                        let start = s_position.x;
                        maxX = maxX > start ? maxX : start;
                      }
                    }
                  });

                  return {
                    x: maxX,
                    y: box.y,
                    width: 99999,
                    height: 0,
                  };
                }
              }
              return {
                x: this.startLen,
                y: box.y,
                width: 999999,
                height: 0,
              };
            },
          },
          connecting: {
            connector: "smooth",
            allowPort: true,
            allowNode: false, //禁止不通过连接桩直接连接到节点
            allowEdge: false, //禁止连接到边
            allowBlank: false, //禁止连接到空白地区
            allowLoop: false, //禁止创建循环连线
            allowMulti: "withPort", //禁止一个port连接到多个port
            snap: {
              radius: 50,
            },
            createEdge(args) {
              return this.createEdge({
                shape: "custom-edge",
              });
            },
          },
          // resizing: {
          //     enabled: (node) => {
          //         return true;
          //     }
          // }
        });
        this.renderGanttGroup();
        this.listenerInit(this.graph);
        //创建当天线条
        this.nowDayLineInit(tableHeight, header);
        //创建gantt条纹
        this.ganttListInit();
        // 朱元璋
        for (let i = 0, height_gantt = 0; i < this.ganttList.length; i++) {
          let color_ = this.paletteInitStatus(this.ganttList[i]);
          height_gantt += this.rowHeightList[i];
          let start_len_item =
            parseInt(
              this.calcDiffOneDay(this.ganttList[i].startTime) *
                this.dayDependLen
            ) + this.startLen;
          let item_len = parseInt(
            this.ganttList[i].allTime * this.dayDependLen * this.globalScale_v
          );
          if (item_len === 0) {
            continue;
          }
          let percent = this.SetWorkingHoursRatio(this.ganttList[i]);
          let text_tip = this.ganttList[i].nodeKey;
          let uid = this.ganttList[i].ganttItemUuid;

          // 使用示例
          let progress_color = color_.progress_color;
          let all_color = color_.all_color;
          // if (
          //   this.statusLegend === "group" &&
          //   this.status[this.ganttList[i].status].statusCategory != "DONE"
          // ) {
          //   if (this.checkEndDate(this.ganttList[i].dueTime) == 0) {
          //     progress_color = "rgb(230,162,60)";
          //     all_color = "rgb(230,162,60)";
          //   }
          //   if (this.checkEndDate(this.ganttList[i].dueTime) == 1) {
          //     progress_color = "rgb(245,108,108)";
          //     all_color = "rgb(245,108,108)";
          //   }
          // }

          let gantt_item_height = this.ganttItemHeight;
          let dragTop = this.ganttDragTop;
          let globalScale = this.globalScale;
          //添加node
          let parent = this.graph.addNode({
            x: start_len_item * this.globalScale_v,
            y: height_gantt + ganttitem_top,
            width: item_len,
            height: gantt_item_height,
            zIndex: 10,
            shape: "html",
            id: uid,
            //链接桩
            ports: {
              items: [
                {
                  id: "start",
                  group: "in",
                  attrs: {
                    circle: {
                      r: 4,
                      magnet: true,
                      stroke: "#31d0c6",
                      strokeWidth: 1,
                      fill: "#fff",
                    },
                  },
                },
                {
                  id: "isEnd",
                  group: "out",
                  attrs: {
                    circle: {
                      r: 4,
                      magnet: true,
                      stroke: "#31d0c6",
                      strokeWidth: 1,
                      fill: "#fff",
                    },
                  },
                },
              ],
              groups: {
                in: {
                  position: { name: "left" },
                  attrs: {
                    fo: {
                      width: 12,
                      height: 12,
                      x: -6,
                      y: -6,
                      magnet: "true",
                    },
                  },
                  zIndex: 10,
                },
                out: {
                  position: { name: "right" },
                  attrs: {
                    fo: {
                      width: 12,
                      height: 12,
                      x: -6,
                      y: -6,
                      magnet: "true",
                    },
                  },
                  zIndex: 10,
                },
              },
            },
            html() {
              const wrap = document.createElement("div");
              wrap.innerHTML = `
                <div style="width:${percent}%;height:100%;background-color:${all_color};border-radius:8px;"><div>
                <div style="background-color:transparent;width:100%;height:100%;position:absolute;top:0;left:0;line-height:30px;padding-left:10px;">
                  <div style="transform:scale(${
                    1 / globalScale
                  },1);overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color:#fff;box-sizing:border-box;transform-origin: left;">${text_tip}</div>
                </div>
            `;
              wrap.style.width = "100%";
              wrap.style.height = "100%";
              wrap.style.borderRadius = "8px";
              wrap.style.background = progress_color;
              wrap.style.display = "flex";
              // wrap.innerText = "Hello";
              return wrap;
            },
          });
          //添加拖动条
          if(parent.store.data.size.width>100) {
          let child = this.graph.addNode({
            shape: "rect",
            x: (start_len_item - 20) * this.globalScale_v + item_len,
            y: height_gantt + +ganttitem_top + dragTop,
            width: 2 / globalScale,
            height: 18,
            zIndex: 100,
            attrs: {
              body: {
                fill: "#fff",
                stroke: "transparent",
                cursor: "ew-resize",
              },
            },
          });
          parent.addChild(child);
          }
          

        }
        this.isShowDetailInfo = true;
        this.renderConnect();
        this.highLight(this.itemId);
        this.loading = false;
        const scrollElem = this.$refs.gantt_right;
        // 监听滚动事件
        scrollElem.addEventListener("scroll", this.scrollHandler, true);
        this.$nextTick(() => {
          this.show_person_var = JSON.parse(
            JSON.stringify(this.showpaletteStatusObj)
          );

          if (this.right_scrollTop) {
            let right = document.querySelector(".right");
            right.scrollTop = this.right_scrollTop;
            right.scrollLeft = this.right_scrollLeft;
          }
        });
      });
    },
    checkEndDate(endDateString) {
      const endDate = new Date(endDateString);
      const today = new Date();
      const diffInMilliseconds = endDate - today;
      const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
      if (diffInDays === 0) {
        return 0; // 结束日期是今天
      } else if (diffInDays < 1) {
        return 1; // 结束日期距离今天小于1天（但不是今天）
      } else {
        return 2; // 结束日期距离今天大于或等于1天
      }
    },

    scrollHandler(event) {
      if (event.target.scrollTop > 0) {
        this.right_scrollTop = event.target.scrollTop;
      }

      if (event.target.scrollLeft > 0) {
        this.right_scrollLeft = event.target.scrollLeft;
      }
    },
    //当前天数线条
    nowDayLineInit(tableHeight, header_height) {
      let start;
      if (this.globalStartTime) {
        start = moment(this.globalStartTime).year();
      } else {
        start = moment().year();
      }
      let days = this.calcDiffDays(start);
      let x_len = this.startLen + days * this.dayDependLen;
      const currentDate = moment();
      const date = currentDate.format("M/D");
      const edge = this.graph.addEdge({
        //这里y为0其他node位移会出现故障，不知道为什么
        // shape: 'circle',
        id: "today_line",
        source: { x: x_len * this.globalScale_v, y: 0 },
        target: { x: x_len * this.globalScale_v, y: tableHeight },
        attrs: {
          line: {
            stroke: "#FFC303",
            targetMarker: {
              tagName: "circle",
              r: 2,
              cx: 2,
              fill: "#FFC303",
            },
            zIndex: 0,
          },
        },
      });
      let that = this;
      let globalScale = this.globalScale;

      let today_node = this.graph_header.addNode({
        id: "gantt_today",
        x: (x_len - 25) * this.globalScale_v,
        y: header_height / 2 - 5,
        width: 50,
        height: header_height / 2 + 5,
        shape: "html",
        zIndex: 9999999,

        html() {
          const con = document.createElement("div");
          con.innerHTML = `
                <div style="width:${
                  that.$t("today") == "Today" ? 60 : 50
                }px;font-size: 16px;
                padding-left: 5px;
                border-left:2.5px solid #FFC303;background:#FFC303;color:white;transform:scale(${
                  1 / globalScale
                },1);border-radius:8px">
                ${that.$t("today")}
              </div>
            `;

          return con;
        },
      });

      this.$nextTick(() => {
        let right = document.querySelector(".right");
        let right_group = document.querySelector(".right_group");
        let width = right.clientWidth; //视口宽度
        let x_left = x_len * this.globalScale_v;
        this.nowLeft = x_left;
        if (x_left >= width) {
          right.scrollLeft = parseInt(x_left - width / 2);
          right_group.scrollLeft = parseInt(x_left - width / 2);
        }
      });
    },
    get_style(data) {
      if (this.statusLegend == "group") {
        return "";
      }
      for (let i of this.show_person_var) {
        if (i.person == data.person) {
          return i.color.progress_color;
        }
      }
      return this.show_person_var[this.show_person_var.length - 1].color
        .progress_color;
    },
    //头部画布渲染
    async renderGanttGroup() {
      if (this.graph_header) {
        this.graph_header.dispose();
      }
      let header = document.querySelector("#header_tree_node").offsetHeight;
      let tableWidth = this.tableWidth;
      let globalScale = this.globalScale;
      this.$refs.container_group.style.transform = `scale(${this.globalScale},1.0)`;
      this.graph_header = new Graph({
        container: document.getElementById("container_group"),

        width: tableWidth,
        autoResize: true, // 自动缩放大小
        background: {
          color: "#fff",
        },
        grid: {
          size: this.gridLen,
          visible: true,
          type: "doubleMesh",
          args: [
            {
              color: "#eee", // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: "#ddd", // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 7, // 主次网格线间隔
            },
          ],
        },
        selecting: {
          enabled: true,
          rubberband: true,
          filter: function (a, b) {
            return false;
          },
          // filter: ["rect"], // 'rect' 类型节点不能被选中
          showNodeSelectionBox: true,
        },
        interacting: function (cellView) {
          if (
            cellView.cell.id.split("@")[0] == "gantt_today" ||
            cellView.cell.id.split("@")[0] == "line_node"
          ) {
            return { nodeMovable: false };
          }
          return {
            nodeMovable: true, //节点是否可以被移动
            edgeMovable: false, //边是否可以被移动
            edgeLabelMovable: false, //边的标签是否可以被移动
          };
        },
        translating: {
          restrict: (params) => {
            return {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
            };
          },
        },
      });

      this.graph_header.addNode({
        id: "header_node",
        shape: "rect",
        x: 0,
        y: 0,
        width: tableWidth,
        height: header,
        attrs: {
          body: {
            fill: "white",
            stroke: "transparent",
            cursor: "default",
          },
        },
      });
      this.renderAsMonth(header);
    },
    async renderAsMonth(header_height) {
      if (this.tableData.length === 0) {
        return;
      }
      this.header_height = header_height;
      let result = this.getHeaderTimeLen();

      let length = 0;
      let temp = 0;
      let start = moment(this.globalStartTime).year() || 2023;
      let days = this.calcDiffDays(start);
      let x_len = this.startLen + days * this.dayDependLen;
      const currentDate = moment();
      const now_date = currentDate.format("M/D");
      let scale_fix = 1 / this.globalScale;
      result.forEach((item) => {
        length += item.days * this.dayDependLen;
      });
      this.header_edge = this.graph_header.addEdge({
        source: { x: this.startLen, y: header_height },
        target: { x: length, y: header_height },
        movable: false,
        attrs: {
          line: {
            stroke: "transparent",
          },
        },
      });

      let globalScale = this.globalScale;

      for (let i = 0; i < result.length; i++) {
        this.graph.addNode({
          id: "line_node@" + i,
          x: result[i].t * this.globalScale_v,
          y: 0,
          width: 2,
          height: 9999,
          zIndex: 1,
          shape: "html",
          html() {
            const con = document.createElement("div");
            con.innerHTML = `
                <div arg='${result[i].text}' style="width:50px;font-size: 16px;
                padding-left: 5px;height:9999px;color: white;
                border-left:2.5px solid #DDDFE6;padding-bottom: 5px;transform:scale(${
                  1 / globalScale
                },1);">
                .
              </div>
            `;

            return con;
          },
        });

        this.graph_header.addNode({
          id: "line_node@" + i + "i",
          x: result[i].t * this.globalScale_v,
          y: header_height / 2 - 5,
          width: 50,
          height: header_height / 2 + 5,
          shape: "html",
          html() {
            const con = document.createElement("div");
            con.innerHTML = `
                <div style="width:50px;font-size: 16px;
                padding-left: 5px;
                border-left:2.5px solid #DDDFE6;padding-bottom: 5px;text-wrap: nowrap;transform:scale(${
                  1 / globalScale
                },1);">
                ${result[i].text}
              </div>
            `;

            return con;
          },
        });
        let that = this;

        if (this.statusCell != "byYear") {
          this.graph_header.addNode({
            id: "line_node@" + i + "yea",

            x: result[i].t * this.globalScale_v,
            y: 0,
            width: 50,
            height: header_height / 2 + 5,
            shape: "html",
            html() {
              const con = document.createElement("div");
              con.innerHTML = `
                <div style="width:50px;font-size: 12px;
                padding-left: 6px;color:#808080;
                border-left:2.5px solid #DDDFE6;padding-bottom: 5px;text-wrap: nowrap;transform:scale(${
                  1 / globalScale
                },1);">
                ${
                  result[i].year +
                  (that.$t("tara.tara_lang") == "ch" ? that.$t("year") : "")
                }
              </div>
            `;

              return con;
            },
          });
        }

        temp += result[i].days * this.dayDependLen;
      }
    },
    getHeaderTimeLen() {
      function convertYearMonthStringToDate(yearMonthStr) {
        // 验证输入格式
        if (!/^\d{4}-\d{1,2}$/.test(yearMonthStr)) {
          throw new Error("Invalid year-month format");
        }

        // 添加前置零以确保月份始终是两位数
        const [year, monthStr] = yearMonthStr.split("-");
        const month = monthStr.padStart(2, "0"); // 如果月份少于两位数，则在前面添加0

        // 注意：JavaScript的月份是从0开始的，所以需要减1
        // 使用Date.UTC()创建一个新的Date对象，设置为该月的第一天，时间为00:00:00 UTC
        const date = new Date(
          Date.UTC(Number(year), Number(month) - 1, 1, 0, 0, 0)
        );

        // 返回Date对象
        return date;
      }

      let start = moment(this.globalStartTime).year();
      let end = moment(this.globalDueTime).year();
      let betweenYears = 2+ parseInt(end) - parseInt(start);
      if (this.statusCell === "byMonth") {
        const length_month = 12 * betweenYears;
        const monthDays = [];

        const monthNames = [
          this.$t("node.date.January"),
          this.$t("node.date.February"),
          this.$t("node.date.March"),
          this.$t("node.date.April"),
          this.$t("node.date.May"),
          this.$t("node.date.June"),
          this.$t("node.date.July"),
          this.$t("node.date.August"),
          this.$t("node.date.September"),
          this.$t("node.date.October"),
          this.$t("node.date.November"),
          this.$t("node.date.December"),
        ];
        for (let i = 1; i <= length_month; i++) {
          const month = moment([start + parseInt((i - 1) / 12), (i - 1) % 12]);
          let text_show = monthNames[(i - 1) % 12];
          // if (i % 12 === 1) {
          //   text_show = parseInt(start + (i - 1) / 12) + this.$t("yearMonth");
          // }
          monthDays.push({
            text:
              this.$t("tara.tara_lang") == "ch"
                ? ((i - 1) % 12) + 1 + this.$t("month")
                : text_show,
            year: parseInt(start + (i - 1) / 12),
            t:
              parseInt(
                (this.calcDiffOneDay(
                  convertYearMonthStringToDate(
                    parseInt(start + (i - 1) / 12) + "-" + (((i - 1) % 12) + 1)
                  )
                ) -
                  1) *
                  this.dayDependLen
              ) + this.startLen,
            days: month.daysInMonth(),
          });
        }

        return monthDays;
      } else if (this.statusCell === "byQuarter") {
        const length_quarter = 4 * betweenYears;
        const quarterDays = [];
        const quarterNames = ["-01-01", "-04-01", "-07-01", "-10-01"];
        for (let i = 1; i <= length_quarter; i++) {
          let nowYear = start + parseInt((i - 1) / 4);
          let nowYearText = nowYear + quarterNames[(i - 1) % 4];
          let quarter_now = moment(nowYearText).quarter();
          let quarter_now_days = 0;
          let text_show = quarter_now + this.$t("gantt.Quarter");
          // if(quarter_now === 1){
          //     text_show = nowYear + " 1季度"
          // }
          for (
            let month = quarter_now * 3 - 2;
            month <= quarter_now * 3;
            month++
          ) {
            quarter_now_days += moment([nowYear, month - 1]).daysInMonth();
          }
          quarterDays.push({
            text:
              this.$t("tara.tara_lang") == "ch"
                ? text_show
                : "Q" + text_show.slice(0, 1),
            days: quarter_now_days,
            year: nowYear,
            t:
              parseInt(
                this.calcDiffOneDay(
                  convertYearMonthStringToDate(nowYearText.slice(0, -3))
                )
              ) *
                this.dayDependLen +
              this.startLen,
          });
        }
        return quarterDays;
      } else {
        const yearDays = [];
        for (let i = start; i <= end; i++) {
          const isLeap = moment().year(i).isLeapYear();
          const daysInYear = isLeap ? 366 : 365;
          yearDays.push({
            year: moment().year(i),
            t:
              parseInt(
                this.calcDiffOneDay(convertYearMonthStringToDate(i + "-1"))
              ) *
                this.dayDependLen +
              this.startLen,
            text:
              i + (this.$t("tara.tara_lang") == "ch" ? this.$t("year") : ""),
            days: daysInYear,
          });
        }
        return yearDays;
      }
    },
    addScale() {
      if (this.globalScale_v > 0.9) {
        this.$message.warning(this.$t("gantt.ScaledUpToUpperLimit"));
        return;
      }
      let scale = (this.globalScale_v * 10 + 1) / 10;
      this.globalScale_v = scale;
      this.startLen = 40 / scale;
      this.renderGantt();
    },
    reduceScale() {
      if (this.globalScale_v <= 0.1) {
        this.$message.warning(this.$t("gantt.ScaledDownToLowerLimit"));
        return;
      }
      let scale = (this.globalScale_v * 10 - 1) / 10;
      this.globalScale_v = scale;
      this.startLen = 40 / scale;

      this.renderGantt();
    },

    //4.弹窗部分---------------------------------------------------------

    //模糊搜索
    async get_all_node_filetype(nodeKeyList) {
      const params = {
        projectId: this.get_pid(),
        data: nodeKeyList,
      };
      let all_node_info = await searchByKeys(params);
      return all_node_info;
    },
    changeSelectBugNodes(flag) {
      flag ? this.get_add_nodes_options("") : "";
    },
    // 获得模糊搜索的节点列表
    get_add_nodes_options(fuzzyKey) {
      this.add_nodes_options_loading = true;
      get_nodes_by_fuzzykey(this.get_pid(), fuzzyKey).then((res) => {
        this.add_nodes_options_loading = false;
        this.add_nodes_options = res.filter((item) => {
          let flag = true;
          return flag;
        });
      });
    },
    get_selected_nodes() {
      this.node_list_loading = true;
      if (this.parentId === "") {
        this.insertRootNodeToChart(this.add_nodes);
      } else {
        this.insertChildNodeToChart(this.add_nodes);
      }
      this.add_nodes = [];
    },
    //表单提交处理
    async submitForm(formName) {
      if (formName === "form_newGanttChart") {
        let valid = await this.$refs[formName].validate();
        if (valid) {
          let data = {};
          data.description = this.form_newGanttChart.desc;
          data.name = this.form_newGanttChart.name;
          await addGanttChart(this.get_pid(), data);
          this.$message.success(this.$t("gantt.addedSuccessfully"));
          this.addGanttChartDialog = false;
          await this.initGanttChartsList();
          if (this.ganttChartsList.length) {
            this.selectedGanttChart =
              // this.ganttChartsList[this.ganttChartsList.length - 1];
              this.ganttChartsList[0];
          }
          this.form_newGanttChart = { name: "", desc: "" };
          await this.closeWebsocket();
          await this.initWebsocket();
          this.initGanttNodes();
          this.judgeGuide();
          this.cur_gant_id = this.selectedGanttChart.ganttChartId;
        } else {
          this.$message.warning(this.$t("gantt.NewGanttChartFailed"));
        }
      } else if (formName === "form_GanttSetting") {
        // this.addGanttSettingDialog = false
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {};
            params.name = this.form_GanttSetting.name;
            params.description = this.form_GanttSetting.desc;
            params.notificationRules = [];
            this.form_GanttSetting.selectRules.forEach((item) => {
              if (item.name != "") {
                params.notificationRules.push(item.name);
              }
            });

            setSelectedGanttRules(
              this.get_pid(),
              this.selectedGanttChart.ganttChartId,
              params
            ).then((res) => {
              this.$message.success(this.$t("gantt.SetUpSuccessful"));
              this.cur_gant_id = this.selectedGanttChart.ganttChartId;
              this.initGanttChartsList(this.selectedGanttChart.ganttChartId);
            });

            this.addGanttSettingDialog = false;
          } else {
            this.$message.warning(this.$t("gantt.SetupFailed"));
          }
        });
        //
      }
    },

    //progres弹窗
    progressWorkShow(data) {
      this.closeWorkPop();
    },
    progressShow(data) {
      this.closePopover();
      this.progressData = data;
    },
    handleWorkType(item) {
      this.progressworkType = item.name;
    },
    handleType(item) {
      this.progressType = item.name;
    },
    changeProgressWorkButton(data) {
      if (this.ruleForm.addWorkProgressNum >= 0) {
        if (this.progressworkType === this.$t("gantt.info6")) {
          this.ruleForm.addWorkProgressNum *= 1000;
        } else if (this.progressworkType === this.$t("gantt.info4")) {
          this.ruleForm.addWorkProgressNum *= 8000;
        } else {
          this.ruleForm.addWorkProgressNum *= 40000;
        }
        this.ruleForm.addWorkProgressNum = Math.round(
          this.ruleForm.addWorkProgressNum
        );
        let params = {
          projectId: this.get_pid(),
          ganttItemUuid: data.ganttItemUuid,
          planManHour: this.ruleForm.addWorkProgressNum,
        };
        this.closePopover();
        addWorkTimeProgress(params)
          .then((res) => {
            this.initGanttNodes();
            this.ruleForm.addWorkProgressNum = 0;
          })
          .catch((err) => {});
      }
    },
    changeProgressButton(data) {
      let params = {
        projectId: this.get_pid(),
        ganttItemUuid: data.ganttItemUuid,
        progress: data.progress,
      };
      params.progress = Math.round(params.progress);
      changeProgress(params)
        .then((res) => {
          this.initGanttNodes();
        })
        .catch((err) => {});
    },
    addProgressByTimeButton() {
      if (this.ruleForm.addProgressNum >= 0) {
        if (this.progressType === this.$t("gantt.info6")) {
          this.ruleForm.addProgressNum *= 1;
        } else if (this.progressType === this.$t("gantt.info4")) {
          this.ruleForm.addProgressNum *= 8;
        } else {
          this.ruleForm.addProgressNum *= 40;
        }
        this.ruleForm.addProgressNum = Math.round(this.ruleForm.addProgressNum);

        let params = {
          projectId: this.get_pid(),
          ganttItemUuid: this.progressData.ganttItemUuid,
          manHour: this.ruleForm.addProgressNum,
        };
        this.closeWorkPop();
        addTimeProgress(params).then((res) => {
          this.initGanttNodes();
          this.ruleForm.addProgressNum = 0;
        });
      }
    },
    clickCalcTimeProgress(data) {
      let params = {
        projectId: this.get_pid(),
        ganttItemUuid: data.ganttItemUuid,
      };
      if (this.helpShowNumber(data.planManHour / 8000) != 0) {
        calcTimeProgress(params).then((res) => {
          this.$message.success(this.$t("gantt.ProgressCalculationSuccess"));
          this.initGanttNodes();
        });
      } else {
        this.$message({
          message: this.$t("gantt.tipworkhours"),
          type: "warning",
        });
      }
    },

    // 设置弹窗
    async initRuleList() {
      let result = await getGanttRules();
      result = result.map((item) => ({
        ...item,
        name: item.rule,
      }));
      this.setDependRules = result || [];
    },
    async showDependRuleDialog() {
      this.addGanttSettingDialog = true;
      let tempResult = await getSelectedGanttRules(
        this.get_pid(),
        this.selectedGanttChart.ganttChartId
      );
      this.form_GanttSetting.name = tempResult.name || "";
      this.form_GanttSetting.desc = tempResult.description || "";
      let tempRules = [];
      tempResult.ganttNotificationRuleList.forEach((item) => {
        this.setDependRules.forEach((child) => {
          if (item === child.name) {
            tempRules.push({
              name: child.name,
              description: child.description,
            });
          }
        });
      });
      this.form_GanttSetting.selectRules = tempRules;
      this.addDropList();
    },
    deleteDrop(index) {
      // if (index == 0) {
      //   this.form_GanttSetting.selectRules = [
      //     {
      //       name: "",
      //       description: "",
      //     },
      //   ];
      //   return;
      // }
      this.form_GanttSetting.selectRules.splice(index, 1);
      const len = this.form_GanttSetting.selectRules.length;
      if (this.form_GanttSetting.selectRules[len - 1].name !== "") {
        this.addDropList();
      }
    },
    handleCommandRule(item, index, depend_rule) {
      depend_rule.description = item.description;
      depend_rule.name = item.name;
      if (index + 1 === this.form_GanttSetting.selectRules.length) {
        this.addDropList();
      }
    },
    addDropList() {
      if (
        this.form_GanttSetting.selectRules.length < this.setDependRules.length
      ) {
        this.form_GanttSetting.selectRules.push({ name: "", description: "" });
      } else {
        // this.$message.error("规则数量已达到上限");
      }
    },

    //5.工具函数---------------------------------------------------------

    //绑定滚动条同步
    bindScroller() {
      let left = document.querySelector(".left");
      let right = document.querySelector(".right");
      let left_group = document.querySelector(".left_group");
      let right_group = document.querySelector(".right_group");
      if (left && right && left_group && right_group) {
        right.addEventListener("scroll", function () {
          left.scrollTop = right.scrollTop;
          right_group.scrollLeft = right.scrollLeft;
        });
        left.addEventListener("scroll", (event) => {
          right.scrollTop = left.scrollTop;
          left_group.scrollLeft = left.scrollLeft;
        });
      }
    },
    //绑定中间的拉伸条
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let resize = document.querySelector(".resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          let startX = e.pageX;
          let width = element.offsetWidth;
          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;
          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              element.style.maxWidth = width + moveX + "px";
            }, 5);
          }
          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
      };
      let left = document.querySelector(".left");
      let left_header = document.querySelector(".left_group");
      resizeFn(left, "right");
      resizeFn(left_header, "right");
    },
    //调色盘统计
    showDetailInfo() {
      this.isShowDetailInfo = !this.isShowDetailInfo;
    },
    paletteInit() {
      this.palette = [
        { all_color: "#FF8D1A", progress_color: "#FBC188" },
        { all_color: "#FFC300", progress_color: "#FFE796" },
        { all_color: "#A5D63F", progress_color: "#CDE892" },
        { all_color: "#E35E1F", progress_color: "#F9A788" },
        { all_color: "#FFA800", progress_color: "#FFD07F" },
        { all_color: "#00B39D", progress_color: "#7ED9D1" },
        { all_color: "#00A8D3", progress_color: "#7DC3E3" },
        { all_color: "#5D5CA5", progress_color: "#9F95D3" },
        { all_color: "#9E4D9F", progress_color: "#D491CC" },
        { all_color: "#F28D4D", progress_color: "#F9B38D" },
        { all_color: "#E84C3D", progress_color: "#F47E7F" },
        { all_color: "#3A9CFF", progress_color: "#8DC8FF" },
        { all_color: "#B53471", progress_color: "#E37FB1" },
      ];
    },
    paletteInitStatus(item) {
      let temp;
      let cate;
      // let allTime = item.allTime;
      let allTime = this.helpShowNumber(item.planManHour / 8);
      if (this.statusLegend === "group") {
        temp = item.root_id;
        cate = "group";
      } else if (this.statusLegend === "person") {
        temp = item.person;
        cate = "person";
      }
      if (this.paletteStatusObj[temp]) {
        this.paletteStatusObj[temp].count++;
      } else {
        this.paletteStatusObj[temp] = {};
        this.paletteStatusObj[temp][cate] = temp;
        this.paletteStatusObj[temp].color = this.palette[this.cur_palette];
        this.paletteStatusObj[temp].count = 1;
        this.cur_palette++;
        if (this.cur_palette >= this.palette.length) {
          this.cur_palette = 0;
        }
      }
      if (this.paletteStatusObj[temp].allTime) {
        this.paletteStatusObj[temp].allTime += parseInt(allTime);
      } else {
        this.paletteStatusObj[temp].allTime = parseInt(allTime);
      }
      return this.paletteStatusObj[temp].color;
    },
    //计算2天差距
    calcDiffBetweenDays(start_, end_) {
      let start = moment(start_);
      let end = moment(end_);
      const diff = end.diff(start, "days");
      return diff;
    },
    //计算今天和某一年第一天差多少天
    calcDiffDays(year) {
      const currentDate = moment(); // 获取当前日期
      const firstDayOfYear = moment([year, 0]); // 获取某年的第一天
      const days = currentDate.diff(firstDayOfYear, "days"); // 计算相差的天数
      return days;
    },
    //计算某一天距离第一天差多少天
    calcDiffOneDay(time) {
      let start_day = moment(this.globalStartTime).year() + "-01-01";
      let start = moment(start_day);
      let end = moment(time);
      const diff = end.diff(start, "days");
      return diff;
    },
    //根据ganttItemUuid查找
    findObjectById(id, array) {
      // 遍历数组中的所有对象
      for (let i = 0; i < array.length; i++) {
        // 如果当前对象的id与给定的id匹配，则返回该对象
        if (array[i].ganttItemUuid === id) {
          return array[i];
        }
        // 如果当前对象有子节点，则递归查找
        if (array[i].children && array[i].children.length > 0) {
          let result = this.findObjectById(id, array[i].children);

          // 如果在子节点中找到了匹配的对象，则返回该对象
          if (result) {
            return result;
          }
        }
      }
      // 如果没有找到匹配的对象，则返回null
      return null;
    },
    //根据ganttItemUuid查找
    findObjectByIdReturnParams(id, array, parentItemUuid) {
      // 遍历数组中的所有对象
      for (let i = 0; i < array.length; i++) {
        // 如果当前对象的id与给定的id匹配，则返回该对象
        if (array[i].ganttItemUuid === id) {
          return {
            ganttChartUuid: this.selectedGanttChart.ganttChartId,
            ganttItemUuid: array[i].ganttItemUuid,
            toGanttItemUuid: parentItemUuid,
            index: i + 1,
          };
        }
        // 如果当前对象有子节点，则递归查找
        if (array[i].children && array[i].children.length > 0) {
          let result = this.findObjectByIdReturnParams(
            id,
            array[i].children,
            array[i].ganttItemUuid
          );

          // 如果在子节点中找到了匹配的对象，则返回该对象
          if (result) {
            return result;
          }
        }
      }
      // 如果没有找到匹配的对象，则返回null
      return null;
    },
    //tableData打平数组
    ganttListInit() {
      this.ganttList = this.flattenTableData(this.tableData);
    },
    flattenTableData(tableData) {
      // 定义结果数组
      const result = [];
      // 遍历 tableData 对象中的每一项
      for (const item of tableData) {
        // 如果当前项的 show 属性为 true，则将当前项添加到结果数组中
        if (item.show) {
          result.push(item);
        }
        // 如果当前项有 children 属性，则递归调用 flattenTableData 函数
        if (item.children) {
          result.push(...this.flattenTableData(item.children));
        }
      }
      // 返回结果数组
      return result;
    },
    helpShowNumber(num) {
      if (num || num === 0 || num === "0") {
        num = parseFloat(num);
      } else {
        return this.$t("gantt.ErrorPassingReference");
      }
      if (num % 1 < 0.1) {
        return Math.floor(num);
      } else {
        return num.toFixed(1);
      }
    },
    //判断溢出
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      const type = ev.dataset.type;
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出

        if (type === "chart") {
          this.isShowTooltip = true;
        } else {
          this.isShowName = true;
        }
      } else {
        // 否则为不溢出
        if (type === "chart") {
          this.isShowTooltip = false;
        } else {
          this.isShowName = false;
        }
      }
    },
    //div添加blur
    blurDiv() {
      this.isShowTableList = !this.isShowTableList;
      if (this.hasAddBlur) {
        return;
      }
      document.addEventListener("click", () => {
        this.isShowTableList = false;
        this.hasAddBlur = true;
      });
    },
    showDesc(str) {
      if (!str || str.length === 0) {
        return this.$t("gantt.NoProfileAvailable");
      } else if (str.length >= 200) {
        return str.substr(0, 200) + "......";
      } else {
        return str;
      }
    },
    //引导判断
    judgeGuide() {
      const self = this;
      if (
        sessionStorage.getItem("isGanttTip") ||
        localStorage.getItem("isGanttTip")
      ) {
      } else {
        setTimeout(() => {
          let driver = null;
          if (window.Driver) {
            driver = new window.Driver();
          } else {
            driver = new Driver();
          }
          driver.highlight({
            element: "#add_root",
            popover: {
              title: self.$t("review.tip11"),
              description: self.$t("gantt.TryAddingNodes"),
              position: "bottom-center",
              className: "isGanttTip",
              closeBtnText: self.$t("btn.closeBtn"),
            },
          });
        }, 1000);
      }
    },
    //引导监听
    guideListener() {
      const self = this;
      // 监听首页指引
      window.addEventListener("message", (e) => {
        if (e.data) {
          if (e.data == self.$t("addJs.close")) {
            sessionStorage.setItem("isGanttTip", "888");
            localStorage.setItem("isGanttTip", "888");
          }
        }
      });
    },
    //闪烁高亮
    highLight(itemId) {
      if (!itemId) {
        return;
      }
      //如果已经高亮过
      if (this.flag) {
        return;
      }
      let tempNum = 0;
      let dom = document.querySelector(`[id='${itemId}']`);
      let treeNode = dom.parentNode;
      treeNode.scrollIntoView({ block: "nearest", inline: "start" });
      let timer = setInterval(() => {
        treeNode.style.background =
          tempNum % 2 === 0 ? "#fff" : "rgba(255,195,0,.2)";
        // treeNode.style.color = tempNum % 2 === 0 ? "black" : "#fff";
        tempNum++;
        if (tempNum > 6) {
          clearInterval(timer);
        }
      }, 300);
      this.flag = true;
    },
    closeWorkPop(index, item) {
      this.ruleForm.addWorkProgressNum = 0;
      for (const key in this.$refs) {
        if (key.indexOf("popover-") !== -1 || key.indexOf("workpop-") !== -1) {
          this.$refs[key] && this.$refs[key].doClose();
        }
      }
    },
    closePopover(index, item) {
      //每次只显示一个Popover弹窗 使用doClose()
      // 遍历所有的refs，判断索引名中是否有 'popover-' ;如果有，都使用doClose关掉
      this.ruleForm.addProgressNum = 0;
      for (const key in this.$refs) {
        if (key.indexOf("workpop-") !== -1 || key.indexOf("popover-") !== -1) {
          this.$refs[key] && this.$refs[key].doClose();
        }
      }
    },
    gant_add() {
      if (!this.hasPay) {
        this.$message({
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<span>${this.$t("certificate.Please")}<a href="${
            window.location.origin
          }/profile/certificate_management?target=gant" target="_blank" style="color: blue">${this.$t(
            "certificate.CertificateManagement"
          )}</a>${this.$t("certificate.InstallComponents")}</span>`,
        });
        return;
      }
      this.addGanttChartDialog = true;
    },

    // 对描述进行换行替换处理
    change_description() {
      // 对描述进行换行替换处理
      let arrstr = this.selectedGanttChart.description || "";
      this.newDescription = arrstr.replace(/\n/g, "<br>");
    },
    changeStatus(nodeKey, val) {
      this.tableData.forEach((item) => {
        if (item.nodeKey === nodeKey) {
          item.status = val.statusId;
          item.statusName = val.name;
        }
      });
      this.beforeTableData.forEach((item) => {
        if (item.nodeKey === nodeKey) {
          item.status = val.statusId;
          item.statusName = val.name;
        }
      });
      this.tableData2 = JSON.parse(JSON.stringify(this.tableData));
      this.table_key++;
    },
    //点击节点名称弹出抽屉
    show_drawer(node) {
      clearTimeout(this.tempTimer);
      this.tempTimer = setTimeout(() => {
        const jobAuthorities = this.$store.getters.jobAuthorities;
        if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
          this.$message({
            message: this.$t("board.message.error"),
            type: "warning",
          });
          return;
        }
        this.drawer_loading = true;
        this.$nextTick(() => {
          this.current_node = Object.assign({}, node);
          this.drawer = true;
        });
      }, 300);
    },
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    isShowTooltipEvent(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
    addbr(topic) {
      // 设置变量存储返回值
      let newstr = "";
      // 如果长度超过20，就要截取插入字符
      if (topic.length > 20) {
        // 第一次截取
        newstr = topic.slice(0, 20) + "<br/>";
        // 闭包再次调用，如果截取后的字段长度依然超过20，再次调用，如果没有直接返回当前值
        return newstr + this.addbr(topic.slice(20));
      } else {
        // 直接返回当前值
        return topic;
      }
    },
    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    edit_nodename(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
    //跳转节点
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: item.key,
        },
      });
      window.open(href, "_blank");
    },
    headCallBack() {
      this.drawer_loading = false;
    },
    filtersameNodestatus(arr) {
      let filterArr = this.flattenArrayWithChildren(arr);
      filterArr.forEach((item) => (item.children = []));
      const newArrayWithoutDuplicates = Array.from(
        new Set(filterArr.map((obj) => obj.nodeKey))
      ).map((nodeKey) => filterArr.find((obj) => obj.nodeKey === nodeKey));
      if (this.statusFilter.length != 0) {
        return newArrayWithoutDuplicates.filter((item) =>
          this.statusFilter.includes(item.statusName)
        );
      }
      return newArrayWithoutDuplicates;
    },
    filtersameNodekey(arr) {
      let filterArr = this.flattenArrayWithChildren(arr);
      filterArr.forEach((item) => (item.children = []));
      const newArrayWithoutDuplicates = Array.from(
        new Set(filterArr.map((obj) => obj.nodeKey))
      ).map((nodeKey) => filterArr.find((obj) => obj.nodeKey === nodeKey));
      if (this.personFilter.length != 0) {
        return newArrayWithoutDuplicates.filter((item) =>
          this.personFilter.includes(item.person)
        );
      }
      return newArrayWithoutDuplicates;
    },
    flattenArrayWithChildren(arr) {
      const flattenedArray = [];
      function flatten(obj) {
        flattenedArray.push(obj);
        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((child) => flatten(child));
        }
      }
      arr.forEach((obj) => flatten(obj));
      return flattenedArray;
    },
    handleCommandFilter(data) {
      const getTableDataofPerson = (arr, personValue) => {
        if (!arr || arr.length === 0) {
          return [];
        }
        const result = [];
        for (let i = 0; i < arr.length; i++) {
          const obj = arr[i];
          if (personValue.findIndex((item) => item === obj.person) !== -1) {
            result.push({
              ...obj,
              children: getTableDataofPerson(obj.children, personValue),
            });
          } else if (obj.children && obj.children.length > 0) {
            const children = getTableDataofPerson(obj.children, personValue);
            if (children.length > 0) {
              result.push({ ...obj, children });
            }
          }
        }
        return result;
      };
      const getTableDataofStatus = (arr, statusValue) => {
        if (!arr || arr.length === 0) {
          return [];
        }
        const result = [];
        for (let i = 0; i < arr.length; i++) {
          const obj = arr[i];
          if (statusValue.findIndex((item) => item === obj.statusName) !== -1) {
            result.push({
              ...obj,
              children: getTableDataofStatus(obj.children, statusValue),
            });
          } else if (obj.children && obj.children.length > 0) {
            const children = getTableDataofStatus(obj.children, statusValue);
            if (children.length > 0) {
              result.push({ ...obj, children });
            }
          }
        }
        return result;
      };
      //先判断责任人有没有进行筛选
      if (this.personFilter.length !== 0) {
        this.tableData = getTableDataofPerson(
          this.beforeTableData,
          this.personFilter
        );
        this.tableData = this.filtersameNodekey(this.tableData);
        //在责任人筛选列表的基础上进行状态筛选
        if (this.statusFilter.length !== 0) {
          this.tableData = getTableDataofStatus(
            this.tableData,
            this.statusFilter
          );
          this.tableData = this.filtersameNodestatus(this.tableData);
        }
      } else {
        //责任人没有进行筛选，则直接在所有数据上进行状态筛选
        if (this.statusFilter.length !== 0) {
          this.tableData = getTableDataofStatus(
            this.beforeTableData,
            this.statusFilter
          );
          this.tableData = this.filtersameNodestatus(this.tableData);
        }
      }
      if (
        this.personFilter.length === 0 &&
        this.statusFilter.length === 0 &&
        data == "check"
      ) {
        this.tableData = JSON.parse(JSON.stringify(this.tableData2));
      }

      this.checkAllPerson =
        this.personFilter.length === this.personSet2().length;
      this.isIndeterminatePerson =
        this.personFilter.length > 0 &&
        this.personFilter.length < this.personSet2().length;
      this.checkAllStatus =
        this.statusFilter.length === this.statusSet2().length;
      this.isIndeterminateStatus =
        this.statusFilter.length > 0 &&
        this.statusFilter.length < this.statusSet2().length;
      this.renderGantt();
    },
    handleCheckAllChange(val, suffix) {
      switch (suffix) {
        case "person":
          this.personFilter = val
            ? this.personSet2().map((item) => {
                return item.value;
              })
            : [];
          this.handleCommandFilter();
          this.isIndeterminatePerson = false;
          break;
        case "status":
          this.statusFilter = val
            ? this.statusSet2().map((item) => {
                return item;
              })
            : [];
          this.handleCommandFilter();
          this.isIndeterminateStatus = false;
          break;
      }
    },
    rightClick(e, data) {
      this.isShowMenu = true;
      this.menuLeft = e.pageX;
      this.menuTop = e.pageY;
      this.rightNode = data;
    },
    hiddenRightMenu() {
      this.isShowMenu = false;
    },
    updateMilestone(flag) {
      flag = flag === "False" ? "false" : "true";
      this.tableData.forEach((item) => {
        if (item.nodeKey === this.rightNode.nodeKey) {
          item.milestone = flag;
        }
      });
      this.beforeTableData.forEach((item) => {
        if (item.nodeKey === this.rightNode.nodeKey) {
          item.milestone = flag;
        }
      });
    },
    personSet2() {
      let set = new Set();
      for (const data of this.beforeTableData) {
        set.add(data.person);
      }
      let flag = false;
      let l = Array.from(set)
        .map((item) => {
          if (item === "-") {
            flag = true;
            return {
              value: "-",
              label: "null",
            };
          } else {
            return {
              value: item,
              label: item,
            };
          }
        })
        .filter((item) => item.value !== "-");

      if (flag) {
        l.push({ value: "-", label: "null" });
      }

      return l;
    },
    statusSet2() {
      let set = new Set();
      for (const data of this.beforeTableData) {
        set.add(data.statusName);
      }
      return Array.from(set);
    },
    //6.临时函数函数---------------------------------------------------------
    // 当日线居中
    dayLineCenter() {
      const right = document.querySelector(".right");
      const right_group = document.querySelector(".right_group");
      const width = right.clientWidth;
      right.scrollLeft = parseInt(this.nowLeft - width / 2);
      right_group.scrollLeft = parseInt(this.nowLeft - width / 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.radius_border_left {
  border-radius: 2px 0 0 2px;
}
.radius_border_right {
  border-radius: 0 2px 2px 0;
}
.radius_border_both {
  border-radius: 2px 2px 2px 2px;
}
.status_todo {
  background-color: rgba(166, 166, 166, 1);
}
.status_open {
  background-color: rgba(67, 207, 124, 1);
}
.status_progress {
  background-color: rgba(42, 130, 228, 1);
}
.table1-bar-line {
  height: 20px;
  line-height: 20px;
}
.table1-bar-tip-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
  vertical-align: sub;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.table1 {
  width: 100%;
  height: 406px;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 12px 0 0 20px;
  border-radius: 8px;
  position: relative;
  &-title {
    font-size: 19px;
    font-weight: bold;
  }
  &-content {
    margin-top: 20px;
    & > div {
      width: 100%;
      font-size: 16px;
      color: #808080;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      & > div {
        width: 50%;
      }
    }
  }
  &-footer {
    width: 100%;
    text-align: center;
  }
}
.table1-bar {
  height: 16px;
  width: 100%;
  vertical-align: middle;
  line-height: 16px;
  & > span {
    display: inline-block;
    height: 16px;
  }
}
// 表格样式修改

$tree_node_height: 50px;
/*no*/
$tree_node_center: 30px;
/*no*/
$tree_node_name: 200px;
/*no*/
$tree_node_key: 160px;
/*no*/
.select {
  .checkbox {
    margin: 0 15px 0 15px !important;
    width: 100%;
  }
}
.icon-qizhi {
  color: #ff5733;
  margin-right: 5px;
  font-size: 20px;
}
.isSelected {
  ::v-deep .center_span {
    border: 1px solid #336690;
    background-color: #336690;
    color: #fff;
  }
}
.center_span {
  padding: 6px 8px;
  font-size: 15px;
  color: #303133;
  border-radius: 4px;
  margin-bottom: 1px;
  & > i {
    margin-left: 0px;
  }
}

.menu {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  border-radius: 5px;
}

.checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 10px;
}
.select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
}

.header_group {
  display: flex;
  height: 40px;
  margin-top: 30px;
  flex-direction: row;
  position: relative;
  background: white;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px 0px rgb(191, 191, 191);
  .wrap_img {
    padding-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .wrap_select {
    display: flex;
    flex-direction: column;
    width: 200px;
    // height:200px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 7.08px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1.18px 2.36px 0px rgba(0, 0, 0, 0.25);
  }
  .group_img {
    height: 18px;
  }
  .left_group::-webkit-scrollbar {
    height: 0;
    width: auto;
    border: 1px solid #fff;
  }
  .right_group::-webkit-scrollbar {
    height: 0;
    width: auto;
    border: 1px solid #fff;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
    border: 1px solid #fff;
  }
}
.left_group {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 30px !important;
  /* no */
  max-width: 900px;
}
.right_group {
  overflow: hidden;
  // margin-left: 7px;
  #container_group {
    height: 40px;
    transform-origin: left;
  }
}
#container {
  transform-origin: left;
  box-shadow: 0px 2px 4px 0px #bfbfbf;
}

.content_gantt {
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  position: relative;
  .left {
    overflow-x: scroll;
    overflow-y: auto;
    max-width: 900px;
    position: relative;
    .bind_tree {
      box-shadow: 0px 2px 4px 0px #bfbfbf;
      white-space: nowrap;
      display: inline-block;
      #header_tree_node {
        display: inline-block;
      }
    }
  }
  .right {
    overflow-x: scroll;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
    // transform: scale(1);
  }
  .left::-webkit-scrollbar {
    height: auto;
    width: 0;
    border: 1px solid #fff;
  }
  ::-webkit-scrollbar {
    height: auto;
    width: auto;
    border: 1px solid #fff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
    border: 1px solid #fff;
  }
}
.el-tree {
  // width: 1000px;
}
/*拖拽区div样式*/
.resize {
  cursor: e-resize;
  min-width: 5px;
  font-size: 32px;
  user-select: none;
}
.resize.active {
  background-color: #0090f1;
  min-width: 5px;
}
.resize:hover {
  background-color: #0090f1;
  min-width: 5px;
}
::v-deep .el-tree-node__content .header_node_name .svg-icon {
  display: none;
}
::v-deep .el-tree-node__content:hover .header_node_name .svg-icon {
  display: inline;
}
.node_name {
  display: flex;
  justify-content: flex-end;
  .node {
    margin: 0px auto;
  }
}

#add_root {
  margin: 0px;
  padding: 0px;
}

#header_tree_node {
  box-sizing: border-box;
  white-space: nowrap; /* 防止换行 */
  height: 40px;
  .header_node_name {
    display: inline-block;
    text-align: center;
    // padding-left: 24PX;
    width: $tree_node_name;
    /*no*/
    margin-right: 20px;
  }
  .header_node_key {
    display: inline-block;
    width: $tree_node_key;
    /*no*/
  }
  .header_node_person {
    display: inline-block;
    width: 100px;
    /*no*/
  }
  .header_node_status {
    display: inline-block;

    width: 160px;
    /*no*/
  }
  .header_node_start_time {
    display: inline-block;

    width: 0.8rem;
    /*no*/
  }
  .header_node_start_time_en {
    display: inline-block;

    width: 1rem;
    /*no*/
  }
  .header_node_end_time {
    display: inline-block;
    width: 0.8rem;
    /*no*/
  }
  .header_node_end_time_en {
    display: inline-block;
    width: 1rem;
    /*no*/
  }
  .header_node_all_time {
    display: inline-block;
    width: 200px;
    /*no*/
  }
  .header_node_work_hours {
    display: inline-block;
    width: 200px;
    /*no*/
  }
}
.header > div {
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  color: #303133;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
.flex-box {
  display: flex;
  box-sizing: border-box;
  .header_node_name {
    display: flex;
    align-items: center;
    // width: 100px;行内宽度
    width: $tree_node_name;
    /*no*/
    // text-align: center !important;
    // border: 1px solid black;
  }
  .header_node_key {
    width: $tree_node_key;
    /*no*/
  }
  .header_node_person {
    width: 100px;
    /*no*/
  }
  .header_node_status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    /*no*/
    input {
      user-select: none;
    }
    // .select_status{
    //   width: 70%;
    // }
  }
  .header_node_start_time {
    ::v-deep .el-input__inner {
      text-align: center;
      width: 140px;
      /*no*/
      padding-left: 0;
      padding-right: 0;
    }
    ::v-deep .el-input__prefix {
      display: none;
    }
    ::v-deep .el-input {
      width: auto;
    }
    width: 0.8rem;
  }
  .header_node_end_time {
    margin-left: 20px;
    ::v-deep .el-input__inner {
      text-align: center;
      width: 140px;
      /*no*/
      padding-left: 0;
      padding-right: 0;
    }
    ::v-deep .el-input__prefix {
      display: none;
    }
    ::v-deep .el-input {
      width: auto;
    }
    width: 0.8rem;
  }
  .header_node_all_time {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    /*no*/
  }
  .header_node_work_hours {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-left: -20px;
    /*no*/
  }

  // border-right: 1px solid blue;
}
.flex-box > div {
  box-sizing: border-box;
  height: $tree_node_height;
  /*no*/
  line-height: $tree_node_height;
  /*no*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.edit_work_time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 10%;
  }
  .edit_add_time {
    font-size: 14px;

    width: 20%;
  }
  .percent_all {
    font-size: 14px;

    // border: 1px solid #a6a6a6;
    width: 30%;
    border-radius: 8px;
    line-height: $tree_node_center;
    /*no*/
    height: $tree_node_center;
    /*no*/
    ::v-deep .el-input__inner {
      // width: 70% !important;
      // padding: 0 !important;
      padding-left: 0;
      padding-right: 10px;
    }
    ::v-deep .el-input-number {
      width: 100%;
    }
    ::v-deep .el-input-number__increase,
    ::v-deep .el-input-number__decrease {
      width: 20%;
    }
  }
}
.edit_time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 10%;
  }
  .edit_add_time {
    font-size: 14px;
    // width: 32%;
  }
  .percent_all {
    font-size: 14px;
    // border: 1px solid #a6a6a6;
    width: 30%;
    border-radius: 8px;
    line-height: $tree_node_center;
    /*no*/
    height: $tree_node_center;
    /*no*/
    ::v-deep .el-input__inner {
      // width: 70% !important;
      // padding: 0 !important;
      padding-left: 0;
      padding-right: 10px;
    }
    ::v-deep .el-input-number {
      width: 100%;
    }
    ::v-deep .el-input-number__increase,
    ::v-deep .el-input-number__decrease {
      width: 20%;
    }
  }
}
.dialog_progress {
  .table_tab_progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.progress_dialog {
  display: flex;
  height: 40px;
  /* no */
  align-items: center;
}
::v-deep {
  .el-tree-node__content {
    width: 100%;
    height: $tree_node_height;
    /*no*/
    min-height: $tree_node_height;
    /*no*/
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
  }
  .el-tree-node__content:hover {
    background: #f5f7fa;
  }
  .el-tree-node.is-drop-inner {
    background-color: rgba(3, 127, 190, 0.2);
  }
  .el-tree__drop-indicator {
    height: 2px;
    background-color: blue;
  }
  .datachart_default {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;

    .avatar {
      display: flex;
    }

    .select_item {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
//dialog样式修改
::v-deep .el-dialog {
  width: 25%;
  .el-form-item__label {
    text-align: left;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    display: none;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
    content: "*";
    color: #f56c6c;
    margin-left: 4px;
  }
  .el-textarea {
    textarea {
      min-height: 150px !important;
    }
  }
}
::v-deep .gantt_set_dialog .el-dialog {
  width: 35%;
  padding: 10px;
}
.drop_depend {
  width: 100%;
  .el-dropdown-link {
    position: relative;
    width: 400px;
    height: 32px;
    border: 1px solid rgba(166, 166, 166, 1);
    display: inline-block;
    line-height: 32px;
    border-radius: 4px;
    text-indent: 8px;
    ::v-deep {
      .el-icon--right {
        float: right;
        margin-right: 6px;
        vertical-align: middle;
        line-height: 32px;
      }
    }
  }
  ::v-deep {
    .el-dropdown-menu {
      width: 263px;
    }
  }
}
.text-name {
  display: inline-block;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-link {
  width: 300px;
  padding-left: 6px;
  height: 30px;
  display: flex;
  border: 1px solid rgba(166, 166, 166, 1);
  border-radius: 4px;
  user-select: none;
  ::v-deep {
    .el-icon--right {
      float: right;
      vertical-align: middle;
      line-height: 32px;
    }
  }
}
</style>

<style lang="scss" scoped>
.chart_gantt {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    width: 40%;
    height: 20vh;
    background-color: white;
    flex-wrap: wrap;
  }
}
.showchartgantt {
  margin-bottom: 20px;
}
.tiptitle3 {
  font-weight: 600;
  font-size: 18px; /*no*/
  padding-bottom: 20px;
  color: rgba(56, 56, 56, 1);
  font-family: Source Han Sans CN;
}
::v-deep .el-table thead.is-group th {
  background-color: rgba(81, 146, 255, 1);
  color: #fff;
  font-weight: 400;
}
::v-deep .el-table tr {
  color: rgba(38, 50, 129, 1);
}
::v-deep .el-table__footer-wrapper tbody td {
  color: rgba(38, 50, 129, 1);
}
/* 画布部分 */
.default_arrow {
  position: fixed;
  right: 60px;
  top: 330px;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 6px;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 7.08px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.18px 2.36px 0px rgba(0, 0, 0, 0.25);
  img {
    width: 16px;
    height: 16px;
  }
}
.is_rotate {
  img {
    transform: rotate(180deg);
  }
}
</style>

<style lang="scss" scoped>
.drawer_wrap {
  padding: 20px;
  height: 100%;
  .header_content {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    text-align: left;
    margin: 20px auto 0 auto;
    .header_node {
      display: flex;
      align-items: top;
      margin-left: 0 !important;
      // margin-right: 20px;
    }
    ::v-deep .el-input__inner {
      width: 180px;
    }
  }
  .top-node-id {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    background-color: rgba(229, 229, 229, 1);
    width: 120px;
    height: 28px;
    border-radius: 2px;
    text-align: center;
    span {
      vertical-align: middle;
      line-height: 28px;
    }
  }

  .node-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .baseline_lock {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .box_item_l {
    height: 30px;
    border: 1px solid #014caa;
    border-radius: 5px 0 0 5px;
    padding: 6px 14px;
    background-color: #014caa;
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .box_item_r {
    height: 30px;
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 0 5px 5px 0;
    padding: 0 14px;
    display: flex;
    justify-items: center;
    align-items: center;
    .box_font {
      font-size: 18px;
      color: rgba(255, 195, 0, 1);
      font-weight: 700;
    }
  }
}
.add_nodes {
  text-align: start;
  ::v-deep .el-select {
    .el-tag:first-child {
      display: flex;
      align-items: center;
      .el-select__tags-text {
        display: inline-block;
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .title {
    font-size: 14px;
    color: rgba(144, 147, 153, 1);
    margin-right: 5px;
  }
  // ::v-deep .el-select-dropdown {
  //   // 解决下拉框错位
  //   position: absolute !important;
  //   // top: -277px !important;
  //   left: 0 !important;
  //   max-width: 400px;
  //   .popper__arrow {
  //     display: none;
  //   }
  // }
  // ::v-deep .el-input {
  //   width: 318px;
  //   margin-right: 12px;
  // }#e8eff7
}
</style>

<style lang="scss" scoped>
.schedule-warnning {
  padding: 26px 16px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  border-left: 5px solid #184fa9;
  color: #707070;
  font-size: 16px;
  & > p {
    margin-top: 4px;
    margin-bottom: 4px;
    position: relative;
    padding: 0 0 0 20px;
    & > i {
      top: 4px;
      left: 0;
      position: absolute;
    }
  }
}
.compare {
  display: flex;
  & > div {
    width: 50%;
    img {
      width: 90%;
      box-shadow: 4px 10px 30px 0 rgba(0, 0, 0, 0.15);
    }
    &:first-of-type {
      & > p {
        & > span {
          background-color: #f0f0f0;
          display: inline-block;
          border-radius: 6px;
          padding: 4px;
        }
      }
    }
    &:last-of-type {
      & > p {
        & > span {
          background-color: #fff8e3;
          display: inline-block;
          border-radius: 6px;
          padding: 4px;
          color: #ffc300;
        }
      }
    }
  }
}
// 复写elementUI样式
.el-dropdown-menu {
  overflow-y: auto;
  max-height: 600px !important;
  padding-top: 0;
}
.el-dropdown-menu::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
}
/*滚动条按钮*/
.el-dropdown-menu::-webkit-scrollbar-button {
  display: none;
  background: transparent;
}
.el-dropdown-menu::-webkit-scrollbar-track {
  /* background-color: rgba(48, 100, 143, 1); 滑道全部 */
  background-color: transparent;
}
.el-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: rgba(222, 222, 224);
  // border: solid 1px rgba(48, 100, 143, 1); /*滑动条边框*/
  border-radius: 4px; /*滑动条圆角宽度*/
}
/*横竖滚动条交角*/
.el-dropdown-menu::-webkit-scrollbar-corner {
  /* background-color: rgba(48, 100, 143, 1); */
  background-color: transparent;
}
/*横竖滚动条交角图案*/
.el-dropdown-menu::-webkit-resizer {
  /*background-image: url(/public/img/resizer-inactive.png);*/
  background-repeat: no-repeat;
  background-position: bottom right;
}
.gantt_wrap {
  text-align: left;
  height: fit-content;
  overflow-y: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .backlog_back {
    background-color: rgba(255, 195, 0, 0.15);
  }
  .not_started_back {
    background-color: rgba(229, 229, 229, 1);
  }
  .in_progress_back {
    background-color: rgba(165, 214, 63, 0.15);
  }
  .completed_back {
    background-color: rgba(42, 130, 228, 0.15);
  }
  .title {
    color: rgba(56, 56, 56, 1);
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
  }
  .gantt_main_wrap {
    padding-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .top_options {
      margin-bottom: 22px;
      .el-dropdown-link {
        width: 263px;
        height: 32px;
        // border: 1px solid rgba(166, 166, 166, 1);
        display: inline-block;
        line-height: 32px;
        border-radius: 4px;
        text-indent: 8px;
        ::v-deep {
          .el-icon--right {
            float: right;
            margin-right: 6px;
            vertical-align: middle;
            line-height: 32px;
          }
        }
      }
      .right-dropdown-link {
        background-color: #154da8;
        color: #fff;
      }
      .select_class {
        height: 50px;
        overflow: auto;
      }
      .type_button {
        display: inline-block;
        height: 32px;
        line-height: 32px;
        padding: 0 6px;
        background: rgba(229, 229, 229, 1);
        border-radius: 4px;
        text-align: center;
        color: rgba(128, 128, 128, 1);
        font-size: 12px;
        margin: 0 6px;
        cursor: pointer;
        &:nth-of-type(1) {
        }
      }
      .more_user {
        font-size: 40px;
      }
      .button_checked {
        background: rgba(56, 56, 56, 1);
        color: #fff;
      }
      .user_img_wrap {
        display: inline-block;
        & > span {
          display: inline-block;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: -10px;
          margin-left: 2px;
          margin-right: 2px;
          cursor: pointer;
          &:nth-of-type(1) {
            margin-left: 28px;
          }
          & > img {
            width: 28px;
            height: 28px;
          }
        }
      }
      .right_button_wrap {
        display: flex;
        align-items: center;
        float: right;
        & > .set_button {
          height: 24px;
          width: 24px;
          cursor: pointer;
        }
        & > span {
          font-size: 14px;
          color: rgba(128, 128, 128, 1);
          margin-right: 6px;
        }
        & > div {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          padding: 0 10px;
          text-align: center;
          border: 1px solid rgba(48, 100, 143, 1);
          color: rgba(48, 100, 143, 1);
          cursor: pointer;
          font-size: 14px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }
    }
    .sprint_target {
      width: 100%;
      height: 70px;
      line-height: 20px;
      padding: 20px 10px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0 0;
      color: rgba(128, 128, 128, 1);
      font-size: 16px;
      // text-indent: 20px;
      & > span {
        font-size: 16px;
        margin-left: 6px;
      }
    }
    .sprint_options_hide {
      background-color: #fff;
      height: 30px;
      line-height: 30px;
      border-radius: 0 0 8px 8px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #f9fafc;
        color: #409eff;
      }
    }
    .block_wrap {
      display: flex;
      justify-content: space-between;
      overflow: scroll;
      margin-top: 16px;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      & > div {
        height: 42px;
        line-height: 42px;
        margin-right: 29px;
        border-radius: 4px;
        padding: 0 10px;
        flex-shrink: 0;
        min-width: 350px;
        &:last-of-type {
          margin-right: 0;
        }
        & > span {
          display: inline-block;
          &:nth-of-type(1) {
            float: left;
          }
          &:nth-of-type(2) {
            float: right;
          }
        }
      }
      .backlog_font_color {
        color: rgba(217, 166, 0, 1);
        font-size: 18px;
      }
      .not_started_font_color {
        color: rgba(128, 128, 128, 1);
        font-size: 18px;
      }
      .in_progress_font_color {
        color: rgba(136, 176, 52, 1);
        font-size: 18px;
      }
      .completed_font_color {
        color: rgba(42, 130, 228, 1);
        font-size: 18px;
      }
    }
    .add_node_wrap {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      align-items: center;
      ::v-deep .el-select-dropdown {
        max-width: 400px;
      }
      & > span {
        display: inline-block;
        color: rgba(144, 147, 153, 1);
        font-size: 16px;
        line-height: 30px;
        margin-right: 20px;
      }
      .node_add_confirm {
        width: 60px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: rgba(48, 100, 143, 1);
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        margin-left: 12px;
      }
    }
    .user_block_wrap {
      margin-top: 20px;
      height: 520px;
      overflow: scroll;
      position: relative;
      &::-webkit-scrollbar {
        // display: none; /* Chrome Safari */
      }
      .fake_scroll {
        position: relative;
      }
    }
    #bar3 {
      overflow: scroll;
    }
    .echarts_main_wrap {
      height: 80vh;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 20px;
      & > div {
        width: 48%;
        height: calc(50% - 20px);
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        padding: 12px 0 0 20px;
        & > p {
          color: rgba(128, 128, 128, 1);
          font-size: 14px;
          margin: 0;
        }
        & > div {
          width: 100%;
          height: 100%;
        }
      }
      .bar1 {
        width: 48%;
        height: 450px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 12px 0 0 20px;
        & > p {
          color: rgba(128, 128, 128, 1);
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .inner_user_block {
      display: flex;
      justify-content: space-between;
      & > div {
        position: relative;
        padding: 12px;
        border-radius: 4px;
        margin-right: 29px;
        min-width: 350px;
        flex-shrink: 0;
        &:last-of-type {
          margin-right: 0;
        }
        & > .status_list {
          position: relative;
          border-radius: 4px;
          width: 100%;
          min-height: 100%;
          border: 2px dashed rgb(48, 100, 143);
          // background-color: red;
          background-color: rgba(155, 155, 155);
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          & > div > div {
            height: 100px;
            line-height: 100px;
            width: calc(100% - 12px);
            color: #fff;
            border: 4px dashed rgba(64, 169, 255, 1);
            background-color: rgba(64, 169, 255, 0.3);
            border-radius: 4px;
            text-align: center;
            margin: 6px;
          }
        }
        .node_list {
          & > span {
            & > span {
              cursor: pointer;
              display: inline-block;
              height: 40px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              position: relative;
              &:nth-of-type(1) {
                width: 100%;
                z-index: 3;
              }
              &:nth-of-type(2) {
                width: 96%;
                margin-left: 2%;
                top: -30px;
                z-index: 2;
              }
              &:nth-of-type(3) {
                width: 92%;
                margin-left: 4%;
                top: -60px;
                z-index: 1;
              }
            }
          }
        }
        & > .node_list > div > div {
          width: 100%;
          height: 108px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          margin-bottom: 6px;
          padding: 6px 15px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          & > p {
            margin: 0;
            height: 30px;
            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background-color: rgba(229, 229, 229, 1);
              position: absolute;
              top: 42px;
              left: 0;
            }
            & > span {
              &:nth-of-type(1) {
                color: rgba(56, 56, 56, 1);
                font-size: 18px;
                float: left;
              }
              &:nth-of-type(2) {
                color: rgba(255, 195, 0, 1);
                font-size: 18px;
                float: right;
              }
            }
          }
          & > div {
            clear: both;
            display: flex;
            margin: 16px 0;
            & > span {
              color: rgba(128, 128, 128, 1);
              font-size: 18px;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              img {
                width: 26px;
                height: 26px;
                vertical-align: middle;
              }
              &:nth-of-type(1) {
                width: 100%;
              }
              &:nth-of-type(2) {
                width: 65%;
              }
            }
          }
        }
      }
      .accept_drag {
        background-color: #fff;
        border: 2px dashed rgb(48, 100, 143);
      }
      .accept_drag_focus {
        box-shadow: 0 0 10px rgba(81, 203, 238, 1);
        --box-shadow: 0 0 10px rgba(81, 203, 238, 1);
      }
    }
  }
}
.fix_scale_group {
  box-shadow: 0px 1.18px 2.36px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  .fix_scale {
    ::v-deep .el-button {
      border: none;
      background-color: #fff !important;
      color: rgba(1, 76, 170, 1) !important;
    }
  }
}
.fix_scale_in {
  border-radius: 4px 4px 0 0 !important;
  ::v-deep .el-button {
    border-radius: 4px 4px 0 0 !important;
  }
}
.fix_scale_out {
  border-radius: 0 0 4px 4px !important;
  ::v-deep .el-button {
    border-radius: 0 0 4px 4px !important;
  }
}
::v-deep.el-select-dropdown__item.test-add-option::after {
  content: "";
}

::v-deep .el-scrollbar__view {
  height: 100%;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
</style>

<style lang="scss">
.table1-bar-line {
  height: 20px;
  line-height: 20px;
}
.table1-bar-tip-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
  vertical-align: sub;
}
.el-empty__description {
  p {
    font-size: 18px;
  }
}
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
#bar3 {
  .el-table__body tr:hover > td:not(:first-child) {
    background-color: #5470c6 !important;
    color: white !important;
  }
  .el-table__body tr > td:not(:first-child) {
    cursor: pointer;
  }
  .el-table__row {
    td {
      border-left: 1px solid #e8eff7;
    }
  }
  .el-table__footer {
    .cell {
      background-color: #5470c6;
    }
    td {
      background-color: #5470c6;
      color: white;
      border-left: 1px solid #e8eff7;
    }
    td:nth-child(1) {
      background-color: #e8eff7 !important ;
      .cell {
        color: black;
        background-color: #e8eff7 !important ;
      }
    }
  }
}
.tablechartDataheader {
  background-color: #e8eff7;
  .cell {
    padding: 0 !important;
    color: balck !important;
  }
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.respeople_container {
  color: white;
  background-color: #5470c6;
  position: relative;
  height: 80px;
  overflow: hidden;
  .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 500%;
    transform: rotate(308deg);
    transform-origin: top left;
    border: 1px solid white;
    background-color: white;
    box-sizing: border-box;
  }
  .left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-left: 5px;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 5px;
    padding-right: 5px;
  }
}
.date-picker-red-border {
  input {
    border-color: red !important;
    background: rgb(252, 211, 211) !important;
  }
  input :hover {
    border-color: red !important;
    background: rgb(252, 211, 211) !important;
  }
  input :focus {
    border-color: red !important;
    background: rgb(252, 211, 211) !important;
  }
}
.date-picker-yellow-border {
  input {
    border-color: orange !important;
    background: rgb(246, 223, 187) !important;
  }
  input :hover {
    border-color: orange !important;
    background: rgb(246, 223, 187) !important;
  }
  input :focus {
    border-color: orange !important;
    background: rgb(246, 223, 187) !important;
  }
}
.relationOwnBug .el-select-dropdown__list li:nth-child(1) {
  background-color: rgba(77, 160, 255, 0.35);
  border-radius: 6px 6px 0px 0px;
}
</style>
<style lang="scss" scoped>
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
::v-deep .el-collapse-item__content {
  padding-bottom: 5px;
}

.important_route_btn_1 {
  border: none !important;
  // border-radius: 16px;
  // background-color: rgb(21, 77, 168);
  // color: white;
  // font-size: 16px;
  // padding: 6px 10px;
  // font-family: "思源黑体";
  .btn {
    background-color: rgb(21, 77, 168);
    color: white;
  }
}

.important_route_btn_0 {
  border: none !important;
  .btn {
    background-color: rgb(21, 77, 168);
    color: white;
  }
}
</style>
