<template>
  <div>
    <!-- 导出excel弹窗 -->
    <el-dialog
      :title="$t('file.table.options.dialog.title')"
      width="25%"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      :before-close="handleClose"
      custom-class="excel_export_dialog"
    >
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <span style="margin-right: 20px"> {{ $t("btn.export_tem") }} </span>
        <el-select
          v-model="template"
          :placeholder="$t('placeholder.dropdown')"
          clearable=""
        >
          <el-option
            v-for="item in tem_options"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>

      <el-checkbox v-model="all_fields_item" @change="judge_all">{{
        $t("file.table.options.dialog.box1")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box12")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box4")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled> Key </el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box7")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box10")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box11")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box8")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box9")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box13")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box14")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box15")
      }}</el-checkbox>
      <el-checkbox-group
        v-model="submit_list"
        text-color=" rgba(66, 159, 255, 1)"
      >
        <el-checkbox
          v-for="(item, index) in checkList"
          :key="index"
          :label="item.fieldId"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button
          v-loading="excelExportLoading"
          type="primary"
          @click="submit_Excel"
          >{{ $t("file.table.options.dialog.box6") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getExcelFields, exportExcel } from "@/network/gantt/index.js";
import { all_fields_get, getSystemFields } from "@/network/fileType/index.js";
import { get_templates } from "@/network/template/index.js";
import { downloadLink } from "@/utils/download.js";
export default {
  props: {
    ganttChartId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      must_check: true,
      dialogVisible: false,
      all_fields_item: false,
      submit_list: [],
      checkList: [],
      tem_options: [],
      excelExportLoading: false,
      template: "",
    };
  },
  created() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];
      this.template = "";
    });
  },
  methods: {
    submit_Excel() {
      this.excelExportLoading = true;
      let post_data = {
        systemFieldsIdList: [],
        costomFieldsIdList: [],
        exportTemplateId: this.template,
      };
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.costomFieldsIdList.push(item.fieldId);
        }
      });
      let a = document.querySelectorAll(
        ".top_options .el-select > .el-input input"
      );
      let body_data = {
        ganttName: a[0].value,
        excelHeadChoicesDto: post_data,
      };
      exportExcel({
        projectId: this.get_pid(),
        ganttChartId: this.ganttChartId,
        data: body_data,
      })
        .then(async (res) => {
          // downloadLink(res, true);
          this.$message.success(this.$t("homeTopBar.asyncTask.message"));
          this.dialogVisible = false;
          this.checkList = [];
          this.submit_list = [];
          this.all_fields_item = false;
          this.excelExportLoading = false;
          this.template = "";
        })
        .catch((error) => {
          this.excelExportLoading = false;
          this.template = "";
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.submit_list = [];
      this.checkList = [];
      this.all_fields_item = false;
    },
    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    showDialog() {
      Promise.all([
        getExcelFields({
          projectId: this.get_pid(),
        }),
        getSystemFields(),
        all_fields_get(this.get_pid()),
      ]).then(
        (res) => {
          // 需展示字段
          this.excel_dialog_fields = res[0] || {};
          // 所有系统字段
          this.systemFields_list = res[1] || [];
          // 所有自定义字段
          this.allFields_list = res[2] || [];

          // 筛选系统字段
          this.systemFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.systemFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          // 筛选自定义字段
          this.allFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.costomFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          this.dialogVisible = true;
        },
        (error) => {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
.excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;
    color: rgba(128, 128, 128, 1);

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
    }
  }
}
</style>
