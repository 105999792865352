<template>
  <div class="datachart_default">
    <div class="pic">
      <img src="@/assets/img/nothing.png" alt="" />
    </div>
    <div class="tip">{{ $t('placeholder.table1') }}</div>
    <el-button type="primary" size="medium" @click="show_dialog">
      <i class="iconfont icon-add" style="color: #fff"></i>&nbsp;&nbsp;{{$t('btn.newBtn')}}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "Datachart",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    show_dialog() {
      this.$emit("show_dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.datachart_default {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .tip {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .pic svg {
    font-size: 550px;
  }
}
</style>
