<template>
  <div>
    <div class="right-item-menu box-shadow1">
      <span
        v-if="rightNode.milestone === 'false'"
        class="menu-item operation-text"
        @click="changeMilestone"
        >{{ $t("gantt.SetAsMilestone") }}</span
      >
      <span v-else class="menu-item operation-text" @click="changeMilestone">{{
        $t("gantt.UnsetMilestone")
      }}</span>
      <span class="menu-item operation-text" @click="remove">{{
        $t("gantt.Remove")
      }}</span>
    </div>
    <el-dialog
      append-to-body
      :title="$t('review.tip11')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="30%"
    >
      <span>{{ $t("gantt.RemovingParentNode") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delNode">{{$t("review.tip14")}}</el-button>
        <el-button @click="dialogVisible = false">{{$t("review.tip13")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  delete_gantt_node,
  setMilestone,
} from "@/network/gantt/index.js";
export default {
  name: "RightMenu",
  props: ["rightNode"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    remove() {
      if (this.rightNode.children.length) {
        this.dialogVisible = true;
      } else {
        this.delNode();
      }
    },
    delNode() {
      delete_gantt_node(
        this.get_pid(),
        this.rightNode.ganttChartUuid,
        this.rightNode.ganttItemUuid
      ).then((res) => {
        this.dialogVisible = false;
        this.$emit("hiddenRightMenu");
      });
    },
    changeMilestone() {
      let flag = this.rightNode.milestone === "false" ? "True" : "False";
      setMilestone(this.get_pid(), this.rightNode.ganttItemUuid, flag).then(
        (res) => {
          this.$emit("hiddenRightMenu");
          this.$emit("updateMilestone", flag);
        }
      );
    },
  },
};
</script>

<style scoped>
.right-item-menu {
  width: 140px;
  padding: 5px;
}
.box-shadow1 {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.menu-item {
  display: block;
  margin-top: 5px;
}
.operation-text {
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 3px;
  color: #7e7e7e;
  /*transition: all .5s ease-in;*/
}
.operation-text:hover {
  background-color: #e9eaec;
}
</style>
